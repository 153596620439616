<template>
  <div class="mx-1 mb-3" v-if="canVerb('book_entries', 'index')">
    <div class="mt-2">
      <b-form>
        <b-card class="filter">
          <p>
            <span>Modalità: {{ progressiveLabel }}</span>
            <span v-if="isProgressive">
              :: Data Ultimo Consolidamento:
              {{ customFormatDate(consolidatedAt) }}
            </span>
            <span v-if="isProgressive && previouslyConsolidatedAt">
              :: Data Consolidamento Precedente:
              {{ customFormatDate(previouslyConsolidatedAt) }}
            </span>
          </p>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                  vid="detail_date"
                  name="detail_date"
                  label="Data"
                  v-model="filter.byCalendar.from"
                  @select="onDetailDateSelect"
                  :min="minDate"
              />
            </div>
            <div class="form-group col-md-6 align-self-end">
              <b-button
                  @click="
                  // #686
                  previously_consolidated_at = null;
                  drawFoglioQuadrature();
                "
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  :disabled="!canGenerate || !canConsolidate"
              >Genera
              </b-button
              >
              <!-- <b-button
                v-if="isProgressive"
                @click="
                  // #686
                  previously_consolidated_at = null;
                  filter.byCalendar.from = null;
                  resetData();
                "
                type="button"
                variant="lisaweb"
                size="sm"
                >Ricomincia</b-button
              > -->
              <!-- </div>
            <div class="form-group col-md-3 align-self-end"> -->
              <b-button @click="onViewConsCal" variant="lisaweb"
              >
                <b-icon icon="calendar"></b-icon>
                Consolidamenti
              </b-button
              >
              <!-- class="float-right" -->
            </div>
          </b-row>
        </b-card>
      </b-form>
    </div>
    <div class="mt-2 mb-4">
      <export-options
          :exportUrl="exportUrl"
          :repository="repository"
          :resource="resource"
          :tableRef="$refs[tableRef]"
          :filter="filter"
          :options="[
          {
            code: 'FOGQUAD',
            label: null,
            formats: ['csv', 'pdf'],
          },
        ]"
      ></export-options>
      <b-overlay center :show="isLoading" rounded="sm">
        <div v-if="!isLoading && Object.keys(detailsData).length">
          <!-- Totale Progressivi consolidati -->
          <b-card
              v-if="isProgressive"
              :header="
              'Totale Progressivi consolidati al ' +
              toLocaleDate(
                !previouslyConsolidatedAt
                  ? consolidatedAt
                  : previouslyConsolidatedAt
              )
            "
              header-tag="header"
              class="mt-1"
          >
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableConsolidatedBySector).length">
                <thead>
                <tr>
                  <th :class="{ mw: true, verticalLine: true }">Compagnia</th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="item in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{ sw: true }"
                  >
                    {{ item }}
                  </th>
                  <!-- <th :class="{ sw: true }">Entrate - Provvigioni</th> -->
                  <th :class="{ sw: true }">Oneri Vari</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(sectorGross, id) in tableConsolidatedBySector"
                    :key="'tr-' + id"
                >
                  <td class="verticalLine">
                    {{
                      parseInt(id)
                          ? getInsurers().find((e) => e.value == id).text
                          : "Indefinita"
                    }}
                  </td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'1.' + index1"
                  >
                    {{ toLocaleCurrency(sectorGross[code]) }}
                  </td>
                  <td
                      v-for="(code, index2) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index2 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'2.' + index2"
                  >
                    {{
                      code === "AC"
                          ? toLocaleCurrency(Math.abs(sectorGross[code]))
                          : toLocaleCurrency(sectorGross[code])
                    }}
                  </td>
                  <td
                      v-for="(code, index3) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="'3.' + index3"
                  >
                    {{
                      code !== "EN-PR"
                          ? toLocaleCurrency(sectorGross[code])
                          : toLocaleCurrency(
                              sectorGross["EN"] - sectorGross["PR"]
                          )
                    }}
                  </td>
                  <!-- <td>
                    {{
                      toLocaleCurrency(sectorGross["EN"] - sectorGross["PR"])
                    }}
                  </td> -->
                  <td>
                    {{ toLocaleCurrency(sectorGross["ONERIVARI"]) }}
                  </td>
                </tr>
                <tr class="totals">
                  <td class="verticalLine">Totale</td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T1.' + index1"
                  >
                    {{
                      toLocaleCurrency(
                          treasuryGrossConsolidatedTotalsBySector[code]
                      )
                    }}
                  </td>

                  <td
                      v-for="(code, index2) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index2 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T2.' + index2"
                  >
                    {{
                      toLocaleCurrency(
                          treasuryGrossConsolidatedTotalsBySector[code]
                      )
                    }}
                  </td>

                  <td
                      v-for="(code, index3) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="'T3.' + index3"
                  >
                    <!-- {{
                      toLocaleCurrency(
                        treasuryGrossConsolidatedTotalsBySector[code]
                      )
                    }} -->
                    {{
                      code !== "EN-PR"
                          ? toLocaleCurrency(
                              treasuryGrossConsolidatedTotalsBySector[code]
                          )
                          : toLocaleCurrency(
                              treasuryGrossConsolidatedTotalsBySector["EN"] -
                              treasuryGrossConsolidatedTotalsBySector["PR"]
                          )
                    }}
                  </td>
                  <!-- <td>
                    {{
                      toLocaleCurrency(
                        treasuryGrossConsolidatedTotalsBySector["EN"] -
                          treasuryGrossConsolidatedTotalsBySector["PR"]
                      )
                    }}
                  </td> -->
                  <td>
                    {{
                      toLocaleCurrency(
                          treasuryGrossConsolidatedTotalsBySector["ONERIVARI"]
                      )
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun consolidamento da mostrare.</div>
            </div>
          </b-card>
          <!-- Giornata Contabile -->
          <!-- :header="
            'Giornata Contabile del ' + toLocaleDate(filter.byCalendar.from)
          " -->
          <b-card header-tag="header" class="mt-1">
            <template #header>
              <!-- <div class="float-left">Giornata Contabile</div> -->
              <!-- <b-button @click="saveConsolitated()" v-if="!isProgressive" variant="lisaweb" class="float-right">Consolida</b-button> -->
              <div>
                <!-- v-if="!isProgressive" -->
                <!-- <b-button
                  @click="saveConsolitated()"
                  v-if="canConsolidate"
                  variant="lisaweb"
                  >Consolida</b-button
                > -->
                Giornata Contabile del
                {{ toLocaleDate(filter.byCalendar.from) }}
              </div>
            </template>
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableByInsurerBySector).length">
                <thead>
                <tr>
                  <th :class="{ mw: true, verticalLine: true }">Compagnia</th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="item in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{ sw: true }"
                  >
                    {{ item }}
                  </th>
                  <!-- <th :class="{ sw: true }">Entrate - Provvigioni</th> -->
                  <th :class="{ sw: true }">Oneri Vari</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(sectorGross, id) in tableByInsurerBySector"
                    :key="'tr-' + id"
                >
                  <!-- <td>iteration: {{ index }} insurer id: {{ id }}</td> -->
                  <td class="verticalLine">
                    {{
                      parseInt(id)
                          ? getInsurers().find((e) => e.value == id).text
                          : "Indefinita"
                    }}
                  </td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'1.' + index1"
                  >
                    {{ toLocaleCurrency(sectorGross[code]) }}
                  </td>
                  <td
                      v-for="(code, index2) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index2 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'2.' + index2"
                  >
                    {{
                      code === "AC"
                          ? toLocaleCurrency(Math.abs(sectorGross[code]))
                          : toLocaleCurrency(sectorGross[code])
                    }}
                  </td>

                  <td
                      v-for="(code, index3) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="'3.' + index3"
                  >
                    <!-- add the sectors -->
                    {{
                      code !== "EN-PR"
                          ? toLocaleCurrency(sectorGross[code])
                          : toLocaleCurrency(
                              sectorGross["EN"] - sectorGross["PR"]
                          )
                    }}
                  </td>
                  <!-- <td>
                    {{
                      toLocaleCurrency(sectorGross["EN"] - sectorGross["PR"])
                    }}
                  </td> -->
                  <td>
                    {{ toLocaleCurrency(sectorGross["ONERIVARI"]) }}
                  </td>
                </tr>
                <tr class="totals">
                  <td class="verticalLine">Totale</td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T1.' + index1"
                  >
                    {{ toLocaleCurrency(treasuryGrossTotalsBySector[code]) }}
                  </td>

                  <td
                      v-for="(code, index2) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index2 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T2.' + index2"
                  >
                    {{ toLocaleCurrency(treasuryGrossTotalsBySector[code]) }}
                  </td>

                  <td
                      v-for="(code, index3) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="'T3.' + index3"
                  >
                    <!-- {{ toLocaleCurrency(treasuryGrossTotalsBySector[code]) }} -->
                    {{
                      code !== "EN-PR"
                          ? toLocaleCurrency(treasuryGrossTotalsBySector[code])
                          : toLocaleCurrency(
                              treasuryGrossTotalsBySector["EN"] -
                              treasuryGrossTotalsBySector["PR"]
                          )
                    }}
                  </td>
                  <!-- <td>
                    {{
                      toLocaleCurrency(
                        treasuryGrossTotalsBySector["EN"] -
                          treasuryGrossTotalsBySector["PR"]
                      )
                    }}
                  </td> -->
                  <td>
                    {{
                      toLocaleCurrency(
                          treasuryGrossTotalsBySector["ONERIVARI"]
                      )
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun movimento da mostrare.</div>
            </div>
          </b-card>
          <!-- Giornata Contabile per Codici Mandato -->
          <b-card
              :header="
              'Giornata Contabile del ' +
              toLocaleDate(filter.byCalendar.from) +
              ' suddivisa per Codici Mandato'
            "
              header-tag="header"
              class="mt-1"
          >
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableByMandateCodeBySector).length">
                <thead>
                <tr>
                  <th :class="{ mw: true, verticalLine: true }">
                    Compagnia - Mandato
                  </th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="item in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{ sw: true }"
                  >
                    {{ item }}
                  </th>
                  <!-- <th :class="{ sw: true }">Entrate - Provvigioni</th> -->
                  <th :class="{ sw: true }">Oneri Vari</th>
                </tr>
                </thead>
                <tbody
                    v-for="(
                    sectorGrossByMandate, id
                  ) in tableByMandateCodeBySector"
                    :key="'TB' + id"
                >
                <tr
                    v-for="(mandateData, mandateCode) in sectorGrossByMandate"
                    :key="'tr-' + id + '-' + mandateCode"
                >
                  <td class="verticalLine">
                    {{
                      parseInt(id)
                          ? getInsurers().find((e) => e.value == id).text
                          : "Indefinita"
                    }}
                    - {{ mandateCode }}
                  </td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'MD1-' + mandateCode + '-' + index1"
                  >
                    {{ toLocaleCurrency(mandateData[code]) }}
                  </td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'MD2-' + mandateCode + '-' + index1"
                  >
                    {{
                      code === "AC"
                          ? toLocaleCurrency(Math.abs(mandateData[code]))
                          : toLocaleCurrency(mandateData[code])
                    }}
                  </td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="'MD3-' + mandateCode + '-' + index1"
                  >
                    {{
                      code !== "EN-PR"
                          ? toLocaleCurrency(mandateData[code])
                          : toLocaleCurrency(
                              mandateData["EN"] - mandateData["PR"]
                          )
                    }}
                  </td>
                  <!-- <td>
                    {{
                      toLocaleCurrency(mandateData["EN"] - mandateData["PR"])
                    }}
                  </td> -->
                  <td>
                    {{ toLocaleCurrency(mandateData["ONERIVARI"]) }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun movimento da mostrare.</div>
            </div>
          </b-card>
          <!-- Sospesi -->
          <b-card header="Sospesi" header-tag="header" class="mt-1">
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableSospesi).length">
                <thead>
                <tr>
                  <!-- SS sta per primo -->
                  <th
                      key="SS"
                      :class="{
                        sw: true,
                      }"
                  >
                    Sospesi aperti
                  </th>
                  <!-- SS già disegnato -->
                  <th
                      v-for="key in Object.keys(tableSospesi).filter(
                        (e) => e != 'SS'
                      )"
                      :key="key"
                      :class="{
                        sw: true,
                      }"
                  >
                    <!-- ? `Sospesi regolati ${
                              sectorIndex.find((e) => e.code == "CC").title
                            } e ${
                              sectorIndex.find((e) => e.code == "CA").title
                            }` -->
                    <!-- {{
                      key != "SS"
                        ? key == "CC+CA"
                          ? "Sospesi regolati di cassa"
                          : key == "TOT"
                          ? "Totale sospesi regolati"
                          : `Sospesi regolati ${
                              sectorIndex.find((e) => e.code == key).title
                            }`
                        : "Sospesi aperti"
                    }} -->
                    {{
                      key == "CC+CA"
                          ? "Sospesi regolati di cassa"
                          : key == "TOT"
                              ? "Totale sospesi regolati"
                              : `Sospesi regolati ${
                                  sectorIndex.find((e) => e.code == key).title
                              }`
                    }}
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr>
                  <!-- SS sta per primo -->
                  <td>
                    {{ toLocaleCurrency(tableSospesi["SS"]) }}
                  </td>
                  <!-- SS già disegnato -->
                  <td
                      v-for="[key, value] in Object.entries(
                        tableSospesi
                      ).filter(([k, v]) => k !== 'SS')"
                      :key="key"
                  >
                    {{ toLocaleCurrency(value) }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun movimento di sospesi da mostrare.</div>
            </div>
          </b-card>
          <!-- Acconti -->
          <b-card header="Acconti" header-tag="header" class="mt-1">
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableAcconti).length">
                <thead>
                <tr>
                  <!-- AC sta per primo -->
                  <th
                      key="AC"
                      :class="{
                        sw: true,
                      }"
                  >
                    Acconti aperti
                  </th>
                  <!-- AC già disegnato -->
                  <th
                      v-for="key in Object.keys(tableAcconti).filter(
                        (e) => e != 'AC'
                      )"
                      :key="key"
                      :class="{
                        sw: true,
                      }"
                  >
                    <!-- {{
                      key == "TOT"
                        ? "Totale acconti regolati"
                        : `Acconti regolati ${
                            sectorIndex.find((e) => e.code == key).title
                          }`
                    }} -->
                    <!-- #840 -->
                    {{
                      key == "CC+CA"
                          ? "Acconti regolati di cassa"
                          : key == "TOT"
                              ? "Totale acconti regolati"
                              : `Acconti regolati ${
                                  sectorIndex.find((e) => e.code == key).title
                              }`
                    }}
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr>
                  <!-- AC sta per primo -->
                  <td>
                    {{ toLocaleCurrency(tableAcconti["AC"]) }}
                  </td>
                  <!-- AC già disegnato -->
                  <td
                      v-for="[key, value] in Object.entries(
                        tableAcconti
                      ).filter(([k, v]) => k !== 'AC')"
                      :key="key"
                  >
                    {{ toLocaleCurrency(value) }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun movimento di acconti da mostrare.</div>
            </div>
          </b-card>
          <!-- Abbuoni -->
          <b-card header="Abbuoni" header-tag="header" class="mt-1">
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableAbbuoni).length">
                <thead>
                <tr>
                  <th
                      v-for="key in Object.keys(tableAbbuoni)"
                      :key="key"
                      :class="{
                        sw: true,
                      }"
                  >
                    {{
                      key == "AB+"
                          ? "Abbuoni passivi"
                          : key == "AB-"
                              ? "Abbuoni attivi"
                              : key
                    }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td
                      v-for="[key, value] in Object.entries(tableAbbuoni)"
                      :key="key"
                  >
                    {{ toLocaleCurrency(value) }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun movimento di abbuoni da mostrare.</div>
            </div>
          </b-card>
          <!-- Totali incassi non da regolazione sospesi -->
          <b-card
              header="Totali incassi non da regolazione sospesi"
              header-tag="header"
              class="mt-1"
          >
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableSameDateBySector).length">
                <thead>
                <tr>
                  <!-- <th
                    v-for="(item, index) in getSectorCodes(
                      sectorIndex,
                      'N',
                      'N',
                      'title'
                    )"
                    :key="item.code"
                    :class="{
                      verticalLine:
                        index ===
                        getSectorCodes(sectorIndex, 'N', 'N').length - 1,
                      sw: true,
                    }"
                  > -->
                  <!-- <th
                    v-for="(
                      value, code, index
                    ) in treasuryGrossSameDateTotalsBySector"
                    :key="code"
                    :class="{
                      verticalLine:
                        index ===
                        Object.keys(treasuryGrossSameDateTotalsBySector)
                          .length -
                          1,
                      sw: true,
                    }"
                  > -->
                  <th
                      v-for="(item, index) in getNonZeroSector(
                        treasuryGrossSameDateTotalsBySector,
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getNonZeroSector(
                            treasuryGrossSameDateTotalsBySector,
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                    <!-- {{ sectorIndex.find((e) => e.code == code).title }} -->
                  </th>
                  <th :class="{ sw: true }">Cassa</th>
                  <th :class="{ sw: true }">Totale</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <!-- <td
                    v-for="(code, index1) in getSectorCodes(
                      sectorIndex,
                      'N',
                      'N'
                    )"
                    :class="{
                      verticalLine:
                        index1 ===
                        getSectorCodes(sectorIndex, 'N', 'N').length - 1,
                    }"
                    :key="'T1.' + index1"
                  > -->
                  <!-- <td
                    v-for="(
                      value, code, index1
                    ) in treasuryGrossSameDateTotalsBySector"
                    :class="{
                      verticalLine:
                        index1 ===
                        Object.keys(treasuryGrossSameDateTotalsBySector)
                          .length -
                          1,
                    }"
                    :key="'T1.' + index1"
                  > -->
                  <td
                      v-for="(code, index1) in getNonZeroSector(
                        treasuryGrossSameDateTotalsBySector,
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getNonZeroSector(
                            treasuryGrossSameDateTotalsBySector,
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T1.' + index1"
                  >
                    {{
                      toLocaleCurrency(
                          treasuryGrossSameDateTotalsBySector[code]
                      )
                    }}
                    <!-- {{ toLocaleCurrency(value) }} -->
                  </td>
                  <td>
                    {{
                      toLocaleCurrency(
                          treasuryGrossSameDateTotalsBySector["CC+CA"]
                      )
                    }}
                  </td>
                  <td>
                    {{
                      toLocaleCurrency(
                          sumAllProps(treasuryGrossSameDateTotalsBySector)
                      )
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>
                Nessun incasso non da regolazione sospesi da mostrare.
              </div>
            </div>
          </b-card>
          <!-- Totali incassi complessivi -->
          <b-card
              header="Totali incassi complessivi"
              header-tag="header"
              class="mt-1"
          >
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableOverallBySector).length">
                <thead>
                <tr>
                  <!-- <th
                    v-for="(item, index) in getSectorCodes(
                      sectorIndex,
                      'N',
                      'N',
                      'title'
                    )"
                    :key="item.code"
                    :class="{
                      verticalLine:
                        index ===
                        getSectorCodes(sectorIndex, 'N', 'N').length - 1,
                      sw: true,
                    }"
                  > -->
                  <!-- <th
                    v-for="(
                      value, code, index
                    ) in treasuryGrossOverallTotalsBySector"
                    :key="code"
                    :class="{
                      verticalLine:
                        index ===
                        Object.keys(treasuryGrossOverallTotalsBySector)
                          .length -
                          1,
                      sw: true,
                    }"
                  > -->
                  <th
                      v-for="(item, index) in getNonZeroSector(
                        treasuryGrossOverallTotalsBySector,
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getNonZeroSector(
                            treasuryGrossOverallTotalsBySector,
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                    <!-- {{ sectorIndex.find((e) => e.code == code).title }} -->
                  </th>
                  <th :class="{ sw: true }">Cassa</th>
                  <th :class="{ sw: true }">Totale</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <!-- <td
                    v-for="(code, index1) in getSectorCodes(
                      sectorIndex,
                      'N',
                      'N'
                    )"
                    :class="{
                      verticalLine:
                        index1 ===
                        getSectorCodes(sectorIndex, 'N', 'N').length - 1,
                    }"
                    :key="'T1.' + index1"
                  > -->
                  <!-- <td
                    v-for="(
                      value, code, index1
                    ) in treasuryGrossOverallTotalsBySector"
                    :class="{
                      verticalLine:
                        index1 ===
                        Object.keys(treasuryGrossOverallTotalsBySector)
                          .length -
                          1,
                    }"
                    :key="'T1.' + index1"
                  > -->
                  <td
                      v-for="(code, index1) in getNonZeroSector(
                        treasuryGrossOverallTotalsBySector,
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getNonZeroSector(
                            treasuryGrossOverallTotalsBySector,
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T1.' + index1"
                  >
                    {{
                      toLocaleCurrency(
                          treasuryGrossOverallTotalsBySector[code]
                      )
                    }}
                    <!-- {{ toLocaleCurrency(value) }} -->
                  </td>
                  <td>
                    {{
                      toLocaleCurrency(
                          treasuryGrossOverallTotalsBySector["CC+CA"]
                      )
                    }}
                  </td>
                  <td>
                    {{
                      toLocaleCurrency(
                          sumAllProps(treasuryGrossOverallTotalsBySector)
                      )
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun incasso complessivo da mostrare.</div>
            </div>
          </b-card>
          <!-- Totale Progressivo + Giornata contabile -->
          <!-- header="Totale Progressivo + Giornata contabile" -->
          <b-card v-if="isProgressive" header-tag="header" class="mt-1">
            <template #header>
              <!-- <div class="float-left">Totale Progressivo + Giornata contabile</div>
                <b-button @click="saveConsolitated()" v-if="isProgressive" variant="lisaweb" class="float-right">Consolida</b-button> -->
              <div>
                <!-- v-if="isProgressive && canGenerate" -->
                <!-- <b-button
                  @click="saveConsolitated()"
                  v-if="canConsolidate"
                  variant="lisaweb"
                  >Consolida</b-button
                > -->
                Totale Progressivo + Giornata contabile
              </div>
            </template>
            <div class="table-wrapper" data-type="overflow-x">
              <table v-if="Object.keys(tableConsolidatedTotalsBySector).length">
                <thead>
                <tr>
                  <th :class="{ mw: true, verticalLine: true }">Compagnia</th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="(item, index) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{
                        verticalLine:
                          index ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                        sw: true,
                      }"
                  >
                    {{ item }}
                  </th>
                  <th
                      v-for="item in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N',
                        'title'
                      )"
                      :key="item.code"
                      :class="{ sw: true }"
                  >
                    {{ item }}
                  </th>
                  <!-- <th :class="{ sw: true }">Entrate - Provvigioni</th> -->
                  <th :class="{ sw: true }">Oneri Vari</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(sectorGross, id) in tableConsolidatedTotalsBySector"
                    :key="'tr-' + id"
                >
                  <!-- <td>iteration: {{ index }} insurer id: {{ id }}</td> -->
                  <td class="verticalLine">
                    {{
                      parseInt(id)
                          ? getInsurers().find((e) => e.value == id).text
                          : "Indefinita"
                    }}
                  </td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'1.' + index1"
                  >
                    {{ toLocaleCurrency(sectorGross[code]) }}
                  </td>
                  <td
                      v-for="(code, index2) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index2 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'2.' + index2"
                  >
                    {{
                      code === "AC"
                          ? toLocaleCurrency(Math.abs(sectorGross[code]))
                          : toLocaleCurrency(sectorGross[code])
                    }}
                  </td>

                  <td
                      v-for="(code, index3) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="'3.' + index3"
                  >
                    {{
                      code !== "EN-PR"
                          ? toLocaleCurrency(sectorGross[code])
                          : toLocaleCurrency(
                              sectorGross["EN"] - sectorGross["PR"]
                          )
                    }}
                  </td>
                  <!-- <td>
                    {{
                      toLocaleCurrency(sectorGross["EN"] - sectorGross["PR"])
                    }}
                  </td> -->
                  <td>
                    {{ toLocaleCurrency(sectorGross["ONERIVARI"]) }}
                  </td>
                </tr>
                <tr class="totals">
                  <td class="verticalLine">Totale</td>
                  <td
                      v-for="(code, index1) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index1 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T1.' + index1"
                  >
                    {{
                      toLocaleCurrency(
                          treasuryGrossProgressiveTotalsBySector[code]
                      )
                    }}
                  </td>
                  <td
                      v-for="(code, index2) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )"
                      :class="{
                        verticalLine:
                          index2 ===
                          getSectorCodes(
                            sectorIndex.filter((e) =>
                              e[fieldONERIVARI]
                                ? e[fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }"
                      :key="'T2.' + index2"
                  >
                    {{
                      toLocaleCurrency(
                          treasuryGrossProgressiveTotalsBySector[code]
                      )
                    }}
                  </td>

                  <td
                      v-for="(code, index3) in getSectorCodes(
                        sectorIndex.filter((e) =>
                          e[fieldONERIVARI]
                            ? e[fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="'T3.' + index3"
                  >
                    <!-- {{
                      toLocaleCurrency(
                        treasuryGrossProgressiveTotalsBySector[code]
                      )
                    }} -->
                    {{
                      code !== "EN-PR"
                          ? toLocaleCurrency(
                              treasuryGrossProgressiveTotalsBySector[code]
                          )
                          : toLocaleCurrency(
                              treasuryGrossProgressiveTotalsBySector["EN"] -
                              treasuryGrossProgressiveTotalsBySector["PR"]
                          )
                    }}
                  </td>
                  <!-- <td>
                    {{
                      toLocaleCurrency(
                        treasuryGrossProgressiveTotalsBySector["EN"] -
                          treasuryGrossProgressiveTotalsBySector["PR"]
                      )
                    }}
                  </td> -->
                  <td>
                    {{
                      toLocaleCurrency(
                          treasuryGrossProgressiveTotalsBySector["ONERIVARI"]
                      )
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else>Nessun totale da mostrare.</div>
            </div>
          </b-card>
          <!-- Dettagli -->
          <b-row>
            <b-col align="left">
              <b-button-group
                  v-b-toggle:collapse-details
                  class="my-2 filter-button-group"
                  style="display: flex; align-items: center; gap: 5px"
              >
                <h5>Dettagli</h5>
                <span class="when-open">
                  <b-icon icon="chevron-up" font-scale="1"></b-icon>
                </span>
                <span class="when-closed">
                  <b-icon icon="chevron-down" font-scale="1"></b-icon>
                </span>
              </b-button-group>
            </b-col>
          </b-row>
          <b-collapse id="collapse-details">
            <b-card
                header="Dettaglio giornata contabile"
                header-tag="header"
                class="mt-1 mb-3"
            >
              <div class="table-wrapper" data-type="overflow-x">
                <table v-if="Object.keys(tableAllDetails).length">
                  <thead>
                  <tr>
                    <th>Azioni</th>
                    <th>Compagnia</th>
                    <th>Data</th>
                    <th>Polizza</th>
                    <th>Operazione</th>
                    <th>Contraente</th>
                    <th :class="{ verticalLine: true }">Produttore</th>
                    <th
                        v-for="(item, index) in getSectorCodes(
                          sectorIndex.filter((e) =>
                            e[fieldONERIVARI]
                              ? e[fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N',
                          'title'
                        )"
                        :key="item.code"
                        :class="{
                          verticalLine:
                            index ===
                            getSectorCodes(
                              sectorIndex.filter((e) =>
                                e[fieldONERIVARI]
                                  ? e[fieldONERIVARI].value === 'N'
                                  : true
                              ),
                              'N',
                              'N',
                              'N',
                              'N'
                            ).length -
                              1,
                        }"
                    >
                      {{ item }}
                    </th>
                    <th
                        v-for="(item, index) in getSectorCodes(
                          sectorIndex.filter((e) =>
                            e[fieldONERIVARI]
                              ? e[fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N',
                          'title'
                        )"
                        :key="item.code"
                        :class="{
                          verticalLine:
                            index ===
                            getSectorCodes(
                              sectorIndex.filter((e) =>
                                e[fieldONERIVARI]
                                  ? e[fieldONERIVARI].value === 'N'
                                  : true
                              ),
                              'Y',
                              'N',
                              'N',
                              'N'
                            ).length -
                              1,
                        }"
                    >
                      {{ item }}
                    </th>
                    <th
                        v-for="item in getSectorCodes(
                          sectorIndex.filter((e) =>
                            e[fieldONERIVARI]
                              ? e[fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'Y',
                          'N',
                          'N',
                          'title'
                        )"
                        :key="item.code"
                    >
                      {{ item }}
                    </th>
                    <!-- <th :class="{ sw: true }">Entrate - Provvigioni</th> -->
                    <th>Oneri Vari</th>
                  </tr>
                  </thead>
                  <tbody v-for="(data, index) in tableAllDetails" :key="index">
                  <tr :class="{ virtual: data.virtual }">
                    <td>
                      <b-button
                          v-if="canConsolidate && !data.virtual"
                          size="sm"
                          variant="lisaweb"
                          @click="onEdit(data)"
                          class="mt-1 mr-1"
                          title="Modifica"
                      >
                        <b-icon icon="pencil-square"></b-icon>
                      </b-button>
                    </td>
                    <td>
                      {{
                        parseInt(data.insurer_id)
                            ? getInsurers().find(
                                (e) => e.value == data.insurer_id
                            ).text
                            : "Indefinita"
                      }}
                    </td>
                    <td>
                      {{ toLocaleDate(data.book_date) }}
                    </td>
                    <td>
                      {{
                        data.various_accountings &&
                        data.various_accountings.length
                            ? data.various_accountings[0].pivot
                                .insurance_policy_number
                            : data.insurance_ancillaries &&
                            data.insurance_ancillaries.length
                                ? data.insurance_ancillaries[0].insurance_policy
                                    ? data.insurance_ancillaries[0].insurance_policy
                                        .number
                                    : ""
                                : ""
                      }}
                    </td>
                    <td>
                        <span>
                          {{ renderOperation(data) }}
                        </span>
                    </td>
                    <td>
                        <span>
                          {{ getRegistry(data) }}
                        </span>
                    </td>
                    <td class="verticalLine">
                      {{
                        data.brokers.length
                            ? getSalesmen().find(
                                (e) => e.value == data.brokers[0].id
                            )
                                ? getSalesmen().find(
                                    (e) => e.value == data.brokers[0].id
                                ).text
                                : "--"
                            : "--"
                      }}
                    </td>
                    <td
                        v-for="(code, index1) in getSectorCodes(
                          sectorIndex.filter((e) =>
                            e[fieldONERIVARI]
                              ? e[fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        )"
                        :class="{
                          verticalLine:
                            index1 ===
                            getSectorCodes(
                              sectorIndex.filter((e) =>
                                e[fieldONERIVARI]
                                  ? e[fieldONERIVARI].value === 'N'
                                  : true
                              ),
                              'N',
                              'N',
                              'N',
                              'N'
                            ).length -
                              1,
                        }"
                        :key="'1.' + index1"
                    >
                      {{
                        toLocaleCurrency(
                            getGrossByCode(data.entry_details, code)
                        )
                      }}
                    </td>
                    <td
                        v-for="(code, index2) in getSectorCodes(
                          sectorIndex.filter((e) =>
                            e[fieldONERIVARI]
                              ? e[fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        )"
                        :class="{
                          verticalLine:
                            index2 ===
                            getSectorCodes(
                              sectorIndex.filter((e) =>
                                e[fieldONERIVARI]
                                  ? e[fieldONERIVARI].value === 'N'
                                  : true
                              ),
                              'Y',
                              'N',
                              'N',
                              'N'
                            ).length -
                              1,
                        }"
                        :key="'2.' + index2"
                    >
                      <!-- {{
                        code === "AC"
                          ? toLocaleCurrency(
                              Math.abs(
                                getGrossByCode(data.entry_details, code)
                              )
                            )
                          : toLocaleCurrency(
                              getGrossByCode(data.entry_details, code)
                            )
                      }} -->
                      <!-- #848 qui invece devo mantenere il segno negativo -->
                      {{
                        toLocaleCurrency(
                            getGrossByCode(data.entry_details, code)
                        )
                      }}
                    </td>
                    <td
                        v-for="(code, index3) in getSectorCodes(
                          sectorIndex.filter((e) =>
                            e[fieldONERIVARI]
                              ? e[fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'Y',
                          'N',
                          'N'
                        )"
                        :key="'3.' + index3"
                    >
                      <!-- {{
                        toLocaleCurrency(
                          getByPropVal(
                            data.entry_details,
                            "treasury.sector.code",
                            code,
                            "gross"
                          )
                        )
                      }} -->
                      {{
                        code !== "EN-PR"
                            ? toLocaleCurrency(
                                getByPropVal(
                                    data.entry_details,
                                    "treasury.sector.code",
                                    code,
                                    "gross"
                                )
                            )
                            : toLocaleCurrency(
                                getByPropVal(
                                    data.entry_details,
                                    "treasury.sector.code",
                                    "EN",
                                    "gross"
                                ) -
                                getByPropVal(
                                    data.entry_details,
                                    "treasury.sector.code",
                                    "PR",
                                    "gross"
                                )
                            )
                      }}
                    </td>
                    <!-- <td>
                      {{
                        toLocaleCurrency(
                          getByPropVal(
                            data.entry_details,
                            "treasury.sector.code",
                            "EN",
                            "gross"
                          ) -
                            getByPropVal(
                              data.entry_details,
                              "treasury.sector.code",
                              "PR",
                              "gross"
                            )
                        )
                      }}
                    </td> -->
                    <td>
                      {{
                        toLocaleCurrency(
                            getOneriVariGross(data.entry_details)
                        )
                      }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-card>
          </b-collapse>
          <div class="consolida-btn-wrapper">
            <b-button
                @click="saveConsolitated()"
                v-if="canConsolidate"
                variant="lisaweb"
            >Consolida
            </b-button
            >
          </div>
        </div>
        <div v-else>Dati non disponibili</div>
        <template #overlay>
          <div class="text-center">
            <base-icon name="loading" width="35" height="35"/>
            <p id="cancel-label">Operazione in corso...</p>
          </div>
        </template>
      </b-overlay>
    </div>

    <consolidation-calendar-modal
        v-if="!isLoading && !isConsolidateLoading"
        @view="onDrawFoglioQuadrature"
        :max="consolidatedAt"
        @reload="
        previously_consolidated_at = null;
        filter.byCalendar.from = null;
        resetData();
        onLoadLastConsolidated();
      "
    />
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import ConsolidationCalendarModal from "@/components/modals/viewConsolidationCalendar";
import ExportOptions from "@/components/ExportOptions";
import {mapGetters, mapActions} from "vuex";
import {toLocaleCurrency} from "@/utils/strings";
import {toLocaleDate} from "@/utils/dates";
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import {
  getTreasuryCodes,
  getSectorCodes,
  getByPropVal,
  sumAllProps,
  sumGross,
  sumGrossTotals,
  sumGrossTotalsBySector,
  groupBy,
  filterBy,
} from "@/utils/accounting";
import moment from "moment";

moment.locale("it");

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      filterName: "filterAccountingBook",
      tableRef: "AccountingBookTableRef",
      resource: "accounting_book",
      repository: "accounting_book",
      isLoading: false,
      onLandData: this.$route.params.onLandData
          ? this.$route.params.onLandData
          : {},
      consumedLandData: false,
      // isDrawing: false,
      isConsolidateLoading: false,
      previously_consolidated_at: null,
      filter: this.initFilter(),
      progressive_options: [
        {value: "N", text: "Foglio non progressivo"},
        {value: "Y", text: "Foglio Progressivo"},
      ],
      form: {
        consolidated_treasury: {
          consolidated_at: null,
        },
      },
      detailsData: [],
      detailsDataBySector: [],
      consolidatedData: [],
      treasuryIndex: [],
      sectorIndex: [],
      treasuryGrossTotals: 0,
      treasuryGrossTotalsBySector: 0,
      treasuryGrossConsolidatedTotals: 0,
      treasuryGrossConsolidatedTotalsBySector: 0,
      treasuryGrossSameDateTotals: 0,
      treasuryGrossSameDateTotalsBySector: 0, // CHECK lo uso?
      treasuryGrossOverallTotals: 0,
      treasuryGrossOverallTotalsBySector: 0,
      treasuryGrossProgressiveTotals: 0,
      treasuryGrossProgressiveTotalsBySector: 0,
      tableConsolidated: {},
      tableConsolidatedBySector: {},
      tableByInsurer: {},
      tableByInsurerBySector: {},
      tableByMandateCode: {},
      tableByMandateCodeBySector: {},
      tableSospesi: {},
      tableAcconti: {},
      tableAbbuoni: {},
      tableSameDate: {},
      tableSameDateBySector: {},
      tableOverall: {},
      tableOverallBySector: {},
      tableConsolidatedTotals: {},
      tableConsolidatedTotalsBySector: {},
      tableAllDetails: {},
      tableAllDetailsBySector: {},
      changedIds: [],
      // #1023 TODO quando si saprà il nome del nuovo campo nella sector,
      // o aggiornare la variabile, o sotituirla usando il nome del campo direttamente
      fieldONERIVARI: "is_sundry", // #1023 serve a inglobare tutti i sector che hanno il nuovoCampo a Y, rimuovendo gli stessi dalle tabelle
    };
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    hasSomeAcconti(data) {
      return data.some((e) => e?.treasury?.code === "AC");
    },
    initFilter() {
      let init = {
        byCalendar: {
          from: null,
          to: null,
        },
      };
      return init;
    },
    customFormatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "-";
    },
    getTreasuryCodes,
    getSectorCodes,
    getByPropVal,
    sumAllProps,
    onEdit(item) {
      this.$router.push({
        name: "book_entries.edit",
        params: {
          id: `${item.id}`,
          item: item,
          mode: `${
              this.hasSomeAcconti(item.entry_details)
                  ? "III"
                  : item?.various_accountings?.length
                      ? "V"
                      : "I"
          }`,
          returnUrl: "accounting-book",
          returnData: {
            date_from: this?.filter?.byCalendar?.from,
          },
          type: "edit",
        },
      });
    },
    verifyDataIntegrity() {
      // data[1].entry_details[3].treasury
      let some = this.detailsData.filter((e) =>
          e.entry_details.some((d) => d.treasury === null)
      );
      if (some.length) {
        console.debug(JSON.stringify(some.map((e) => e.entry_details)));
        this.$showSnackbar({
          preset: "info",
          text: "Nella giornata selezionata sono presenti movimenti contabili senza forma di pagamento associata",
          duration: 15000,
        });
      }
    },
    drawFoglioQuadrature() {
      this.resetData();
      let queryString = `perPage=0&byRegistry&byMandateCode&byInsurerParticipant&byInsuranceAncillary&byBroker&byVariousAccounting&byBreakdown&byBookTag&byEntryDetail[detail_date]=${this.filter.byCalendar.from}`;
      this.isLoading = true;
      // this.isDrawing = true;
      let BookEntryRepository = RepositoryFactory.get("book_entry");
      BookEntryRepository.index(queryString)
          .then((response) => {
            this.exportUrl = queryString;
            let tmp;
            // filter entry_details by date (because details contain all detail_date despite the BE already filtered by date)
            const filterByDetailDate = filterBy("entry_details", "detail_date");
            this.detailsData = filterByDetailDate(
                response.data.data,
                this.filter.byCalendar.from
            );
            this.verifyDataIntegrity();
            // sort by insurer_id
            this.detailsData.sort((a, b) => a.insurer_id - b.insurer_id);
            // Foglio Quadratura

            // story- 1182
            for (const item of this.detailsData) {
              for (const detail of item.entry_details) {
                if (detail?.treasury?.code === "EN") {
                  // const grossEN =
                  //     detail.gross -
                  //     this.sumPivot(
                  //         item.insurance_ancillaries[0]?.insurer_participants
                  //             ? item.insurance_ancillaries[0]?.insurer_participants
                  //             : [],
                  //         "gross"
                  //     );
                  const grossEN = detail.gross;
                  this.$set(detail, "gross", grossEN);
                }
              }
            }
            // console.debug("§§§ this.detailsData byEntryDetail[detail_date] §§§");
            // console.debug(this.detailsData);

            // console.debug("§§§ this.consolidatedData byAttribute[consolidated_at] §§§");
            // console.debug(this.consolidatedData);

            // § tableConsolidated (if progressive)
            // I dati sono incompleti, non sono raggrupppati per insurer...
            // {
            //     "id": 1,
            //     "consolidated_at": "2021-12-13",
            //     "treasury_id": 8,
            //     "insurer_id": null,
            //     "gross": 88.88
            // },
            // {
            //     "id": 2,
            //     "consolidated_at": "2021-12-13",
            //     "treasury_id": 7,
            //     "insurer_id": null,
            //     "gross": 77.77
            // }

            // devo ottenere un oggetto raggruppato per insurer (es: 0 -> lo metto "a mano" in DEBUG in quanto i dati sono incompleti):
            // { 0:
            //   { CH: 88.88, CA: 77.77 }
            // }

            // usando una keysMap così:
            // { '1': 'AB',
            //   '2': 'AC',
            //   '3': 'SS',
            //   '4': 'EN',
            //   '5': 'PR',
            //   '6': 'UC',
            //   '7': 'CA',
            //   '8': 'CH',
            //   '9': 'CC'
            // }
            // let keysMap = Object.assign({}, ...this.treasuryIndex.map(item => ({ [item.id]: item.code })));

            //

            this.tableConsolidated = {};

            const groupByInsurerRoot = groupBy("insurer_id", null);
            const dataByConsolidatedInsurer = groupByInsurerRoot(
                this.consolidatedData
            );

            // console.debug( "§§§ dataByConsolidatedInsurer §§§" );
            // console.debug(dataByConsolidatedInsurer);

            // let tmpObj = {}

            // for (const key of Object.keys(dataByConsolidatedInsurer)) {
            //   tmpObj[key] = {};
            //   for(const obj of dataByConsolidatedInsurer[key]) {
            //     tmpObj[key][keysMap[obj.treasury_id]] = obj.gross
            //   }
            // }
            // dataByConsolidatedInsurer = tmpObj;

            // this.tableConsolidated = sumGross(tmpObj, this.treasuryIndex);

            this.tableConsolidated = sumGross(
                dataByConsolidatedInsurer,
                this.treasuryIndex
            );
            // #1023
            for (const object of Object.values(this.tableConsolidated)) {
              for (const [code, gross] of Object.entries(object)) {
                // check if fieldONERIVARI is Y for the given sector's code
                let found = this.sectorIndex.find((e) => e.code === code);
                if (found) {
                  if (
                      found[this.fieldONERIVARI] &&
                      found[this.fieldONERIVARI].value === "Y"
                  ) {
                    if (!object["ONERIVARI"]) {
                      this.$set(object, "ONERIVARI", 0);
                    }
                    object["ONERIVARI"] += gross;
                  }
                }
              }
            }

            // console.debug( "§§§ this.tableConsolidated §§§" );
            // console.debug(this.tableConsolidated);

            this.treasuryGrossConsolidatedTotals = sumGrossTotals(
                Object.values(this.tableConsolidated),
                this.treasuryIndex
            );

            // #1023
            for (const [code, gross] of Object.entries(
                this.treasuryGrossConsolidatedTotals
            )) {
              // check if fieldONERIVARI is Y for the given sector's code
              let found = this.sectorIndex.find((e) => e.code === code);
              if (found) {
                if (
                    found[this.fieldONERIVARI] &&
                    found[this.fieldONERIVARI].value === "Y"
                ) {
                  if (!this.treasuryGrossConsolidatedTotals["ONERIVARI"]) {
                    this.$set(
                        this.treasuryGrossConsolidatedTotals,
                        "ONERIVARI",
                        0
                    );
                  }
                  this.treasuryGrossConsolidatedTotals["ONERIVARI"] += gross;
                }
              }
            }

            // § tableConsolidatedBySector
            // sommo i gross delle casse raggruppandoli per comparto, mantenendoli suddivisi per produttore
            for (const [insurer, data] of Object.entries(
                this.tableConsolidated
            )) {
              this.tableConsolidatedBySector[insurer] = {};
              for (const sector of this.sectorIndex) {
                let gross = 0;
                for (const treasury of sector.treasuries) {
                  gross += data[treasury.code];
                }
                if (sector.code === "AC") {
                  this.tableConsolidatedBySector[insurer][sector.code] =
                      Math.abs(gross);
                } else {
                  this.tableConsolidatedBySector[insurer][sector.code] = gross;
                }
                // #1023
                if (
                    sector[this.fieldONERIVARI] &&
                    sector[this.fieldONERIVARI].value === "Y"
                ) {
                  if (!this.tableConsolidatedBySector[insurer]["ONERIVARI"]) {
                    this.$set(
                        this.tableConsolidatedBySector[insurer],
                        "ONERIVARI",
                        0
                    );
                  }
                  this.tableConsolidatedBySector[insurer]["ONERIVARI"] += gross;
                }
              }
            }
            // console.debug("§§§ this.tableConsolidatedBySector §dataByInsurer502§§");
            // console.debug(this.tableConsolidatedBySector);

            this.treasuryGrossConsolidatedTotalsBySector = sumGrossTotalsBySector(
                Object.values(this.tableConsolidatedBySector),
                this.sectorIndex
            );
            // #1023
            for (const [code, gross] of Object.entries(
                this.treasuryGrossConsolidatedTotalsBySector
            )) {
              // check if fieldONERIVARI is Y for the given sector's code
              let found = this.sectorIndex.find((e) => e.code === code);
              if (found) {
                if (
                    found[this.fieldONERIVARI] &&
                    found[this.fieldONERIVARI].value === "Y"
                ) {
                  if (
                      !this.treasuryGrossConsolidatedTotalsBySector["ONERIVARI"]
                  ) {
                    this.$set(
                        this.treasuryGrossConsolidatedTotalsBySector,
                        "ONERIVARI",
                        0
                    );
                  }
                  this.treasuryGrossConsolidatedTotalsBySector["ONERIVARI"] +=
                      gross;
                }
              }
            }

            // § tableByInsurer
            // step 1. : define the extract function key parameter then extract grouped data by insurer
            const groupByInsurer = groupBy("insurer_id", "entry_details");
            const dataByInsurer = groupByInsurer(this.detailsData);
            // console.debug("§§§ dataByInsurer §§§");
            // console.debug(dataByInsurer);

            // step 2. : loop dataByInsurer on key parameter then
            // for each grouped data, filter by (is_system, is_abstract) combinations and sum the gross property
            this.tableByInsurer = sumGross(dataByInsurer, this.treasuryIndex);
            // console.debug("§§§ this.tableByInsurer §§§");
            // console.debug(this.tableByInsurer);

            // sommo i gross delle casse raggruppandoli per comparto, mantenendoli suddivisi per produttore
            for (const [insurer, data] of Object.entries(this.tableByInsurer)) {
              this.tableByInsurerBySector[insurer] = {};
              for (const sector of this.sectorIndex) {
                let gross = 0;
                for (const treasury of sector.treasuries) {
                  gross += data[treasury.code];
                }
                this.tableByInsurerBySector[insurer][sector.code] = gross;
                // #1023
                if (
                    sector[this.fieldONERIVARI] &&
                    sector[this.fieldONERIVARI].value === "Y"
                ) {
                  if (!this.tableByInsurerBySector[insurer]["ONERIVARI"]) {
                    this.$set(
                        this.tableByInsurerBySector[insurer],
                        "ONERIVARI",
                        0
                    );
                  }
                  this.tableByInsurerBySector[insurer]["ONERIVARI"] += gross;
                }
              }
            }
            // console.debug("§§§ this.tableByInsurerBySector §§§");
            // console.debug(this.tableByInsurerBySector);

            this.treasuryGrossTotals = sumGrossTotals(
                Object.values(this.tableByInsurer),
                this.treasuryIndex
            );

            // treasuryGrossTotalsBySector
            this.treasuryGrossTotalsBySector = sumGrossTotalsBySector(
                Object.values(this.tableByInsurerBySector),
                this.sectorIndex
            );

            // #1023
            for (const [code, gross] of Object.entries(
                this.treasuryGrossTotalsBySector
            )) {
              // check if fieldONERIVARI is Y for the given sector's code
              let found = this.sectorIndex.find((e) => e.code === code);
              if (found) {
                if (
                    found[this.fieldONERIVARI] &&
                    found[this.fieldONERIVARI].value === "Y"
                ) {
                  if (!this.treasuryGrossTotalsBySector["ONERIVARI"]) {
                    this.$set(this.treasuryGrossTotalsBySector, "ONERIVARI", 0);
                  }
                  this.treasuryGrossTotalsBySector["ONERIVARI"] += gross;
                }
              }
            }
            // console.debug( "§§§ this.treasuryGrossTotals §§§" );
            // console.debug(this.treasuryGrossTotals);

            // tableByMandateCode
            // const groupByInsurerRoot = groupBy("insurer_id", null);

            // const dataByInsurerRoot = groupByInsurerRoot(this.detailsData);
            // #547: escludo movimenti vari e recuperi sospesi
            // Tentativo risoluzione bug FDQ 24-10-2024 // tmp = this.excludeDeferredDetails(this.detailsData, false);
            tmp = this.detailsData;
            const dataByInsurerRoot = groupByInsurerRoot(tmp);
            for (const key in dataByInsurerRoot) {
              const groupByMandateCode = groupBy(
                  // "mandate_code_id",
                  "mandate_code.code",
                  // "mandate_code.code",
                  "entry_details",
                  'N/D' // #732: set a default string when mandate_code is missing
              );
              const dataByMandateCode = groupByMandateCode(
                  Object.values(dataByInsurerRoot[key])
              );
              this.tableByMandateCode[key] = sumGross(
                  dataByMandateCode,
                  this.treasuryIndex
              );
            }
            // console.debug("§§§ this.tableByMandateCode §§§");
            // console.debug(this.tableByMandateCode);

            // § tableByMandateCodeBySector
            // sommo i gross delle casse raggruppandoli per comparto, mantenendoli suddivisi per codice mandato
            for (const [insurer, data1] of Object.entries(
                this.tableByMandateCode
            )) {
              this.tableByMandateCodeBySector[insurer] = {};
              for (const [mandate, data2] of Object.entries(data1)) {
                this.tableByMandateCodeBySector[insurer][mandate] = {};
                for (const sector of this.sectorIndex) {
                  let gross = 0;
                  for (const treasury of sector.treasuries) {
                    gross += data2[treasury.code];
                  }
                  this.tableByMandateCodeBySector[insurer][mandate][sector.code] =
                      gross;
                  // #1023
                  if (
                      sector[this.fieldONERIVARI] &&
                      sector[this.fieldONERIVARI].value === "Y"
                  ) {
                    if (
                        !this.tableByMandateCodeBySector[insurer][mandate][
                            "ONERIVARI"
                            ]
                    ) {
                      this.$set(
                          this.tableByMandateCodeBySector[insurer][mandate],
                          "ONERIVARI",
                          0
                      );
                    }
                    this.tableByMandateCodeBySector[insurer][mandate][
                        "ONERIVARI"
                        ] += gross;
                  }
                }
              }
            }
            // console.debug("§§§ this.tableByMandateCodeBySector §§§");
            // console.debug(this.tableByMandateCodeBySector);

            // § tableSospesi
            const totalTreasury = (data, code, sign) => {
              return parseFloat(
                  data
                      .filter(
                          (e) =>
                              e.treasury?.code === code &&
                              (sign !== undefined
                                  ? sign === "+"
                                      ? e.gross >= 0
                                      : e.gross < 0
                                  : true)
                      )
                      .reduce(function (acc, obj) {
                        return acc + obj.gross;
                      }, 0)
                      .toFixed(2)
              );
            };
            const hasSomeTreasury = (data, code) => {
              return data.some((e) => e?.treasury?.code === code);
            };
            const hasNegativeTreasury = (data, code) => {
              return data.some((e) => e?.treasury?.code === code && e.gross < 0);
            };

            // init tableSospesi
            for (const sector of this.sectorIndex) {
              this.tableSospesi[sector.code] = 0;
            }

            // totale SS lo calcolo sul response non filtrato ? NO
            // for (const d of response.data.data) {
            //   let tot = totalTreasury(d.entry_details, "SS");
            //   // totale dei sospesi
            //   this.tableSospesi["SS"] = this.tableSospesi["SS"] + tot;
            // }

            // sospesi aperti: sommo tutte le casse 3, se è 0 allora è chiuso
            // parzialmente aperto: somma delle casse 3 è > 0 ma esiste almeno un cassa 3 con valore < 0
            // chiuso: somma casse 3 è > 0 (e non ci sono casse 3 negative)
            const detailsDataSospesi = this.detailsData.filter((d) => {
              let hasSSS = hasSomeTreasury(d.entry_details, "SS");
              // let hasNegSS = hasNegativeTreasury(d.entry_details, "SS");
              // let totSS = totalTreasury(d.entry_details, "SS", "+");
              // // uso il totale calcolato sul response non filtrato per data
              // let totSS = this.tableSospesi["SS"];
              // aperti e parzialmente aperti
              // return hasSSS && hasNegSS && totSS >= 0;
              return hasSSS;
              // solo aperti
              // return (hasSSS && !hasNegSS)
            });

            // add virtual sector
            this.tableSospesi["CC+CA"] = 0;

            // add total
            this.tableSospesi["TOT"] = 0;

            // set values
            for (const d of detailsDataSospesi) {
              let tot = totalTreasury(d.entry_details, "SS", "+");
              // totale dei sospesi
              this.tableSospesi["SS"] = this.tableSospesi["SS"] + tot;
              // recuperi per comparto
              if (hasNegativeTreasury(d.entry_details, "SS")) {
                const allNegDef = d.entry_details.filter(
                    (e) => e?.treasury?.code == "SS" && e.gross < 0
                );
                for (const negDef of allNegDef) {
                  for (const d of negDef.entry_details) {
                    const sector_code = d.treasury?.sector?.code;
                    this.tableSospesi[sector_code] += d.gross;
                  }
                }
              }
            }
            // set virtual sector CC+CA
            this.tableSospesi["CC+CA"] =
                this.tableSospesi["CC"] + this.tableSospesi["CA"];
            // delete zero valued keys
            for (const key of Object.keys(this.tableSospesi)) {
              if (!["SS"].includes(key)) {
                if (this.tableSospesi[key] === 0) {
                  delete this.tableSospesi[key];
                }
              }
            }
            // SS sta per primo => vedi v-for

            // set total (SUM all keys except SS, CC+CA, TOT)
            this.tableSospesi["TOT"] = Object.entries(this.tableSospesi)
                .filter(([k]) => !["SS", "CC+CA", "TOT"].includes(k))
                .map(([, v]) => v)
                .reduce((accumulator, value) => {
                  return accumulator + value;
                }, 0);

            // card 626 le uniche due colonne da escludere sono i comparti contanti e gli assegni
            this.$delete(this.tableSospesi, "CA");
            this.$delete(this.tableSospesi, "CC");
            // card 839: togliere anche sospesi regolati da abbuono; 21/12/2022: contrordine!
            this.$delete(this.tableSospesi, "AB");

            // § tableAcconti (Advanced Payments)
            // init tableAcconti
            for (const sector of this.sectorIndex) {
              this.tableAcconti[sector.code] = 0;
            }

            // totale AC lo calcolo sul response non filtrato? NO
            // for (const d of response.data.data) {
            //   let tot = totalTreasury(d.entry_details, "AC");
            //   // totale dei acconti
            //   this.tableAcconti["AC"] = this.tableAcconti["AC"] + tot;
            // }

            // // CHECK: non si era detto che gli acconti non sono mai negativi? bàh!?
            // const hasNegativeAdvancedPayments = (data) => {
            //   return data.some((e) => e.treasury?.code == "AC" && e.gross < 0);
            // };
            // acconti aperti: ?
            // parzialmente aperto: ?
            // chiuso: ?
            const detailsDataAcconti = this.detailsData.filter((d) => {
              let hasAC = hasSomeTreasury(d.entry_details, "AC");
              // let hasNegAC = hasNegativeTreasury(d.entry_details, "AC");
              // let totAC = totalTreasury(d.entry_details, "AC");
              // uso il totale calcolato sul response non filtrato per data
              // let totAC = this.tableAcconti["AC"];
              // aperti e parzialmente aperti
              // return hasAC && hasNegAC && totAC >= 0;
              // CHECK: negativo per gli acconti????????????
              // return hasAC && hasNegAC && totAC < 0;
              return hasAC;
              // solo aperti ?
              // return (hasAC && !hasNegAC)
            });

            // add virtual sector #840
            this.tableAcconti["CC+CA"] = 0;

            // add total
            this.tableAcconti["TOT"] = 0;

            // set values
            for (const d of detailsDataAcconti) {
              // card 657
              let tot = totalTreasury(d.entry_details, "AC", "+"); /*+
              Math.abs(totalTreasury(d.entry_details, "AC", "-"));*/ // Non dobbiamo aggiungere i recuperi Acconti che sono quelli col segno negativo
              // totale dei acconti
              this.tableAcconti["AC"] = this.tableAcconti["AC"] + tot;
              // recuperi per comparto
              if (hasNegativeTreasury(d.entry_details, "AC")) {
                // #840 (CHECK: ho scommentato questo vecchio codice)
                const allNegAdv = d.entry_details.filter(
                    (e) => e.treasury?.code == "AC" && e.gross < 0
                );
                for (const negAdv of allNegAdv) {
                  for (const d of negAdv.entry_details) {
                    const sector_code = d.treasury?.sector?.code;
                    this.tableAcconti[sector_code] += d.gross;
                  }
                }

                const allPosUc = d.entry_details.filter(
                    (e) => e.treasury?.code == "UC" && e.gross > 0
                );
                for (const posUc of allPosUc) {
                  for (const d of posUc.entry_details) {
                    const sector_code = d.treasury?.sector?.code;
                    this.tableAcconti[sector_code] += d.gross;
                  }
                }
              }
            }
            // set virtual sector CC+CA #840
            this.tableAcconti["CC+CA"] =
                this.tableAcconti["CC"] + this.tableAcconti["CA"];
            // delete zero valued keys
            for (const key of Object.keys(this.tableAcconti)) {
              if (!["AC"].includes(key)) {
                if (this.tableAcconti[key] === 0) {
                  delete this.tableAcconti[key];
                }
              }
            }

            // AC sta per primo => vedi v-for

            // set total (SUM all keys except AC, CC+CA, TOT)
            this.tableAcconti["TOT"] = Object.entries(this.tableAcconti)
                .filter(([k]) => !["AC", "CC+CA", "TOT"].includes(k))
                .map(([, v]) => v)
                .reduce((accumulator, value) => {
                  return accumulator + value;
                }, 0);
            // #840
            this.$delete(this.tableAcconti, "CA");
            this.$delete(this.tableAcconti, "CC");
            this.$delete(this.tableSospesi, "AB");

            // § tableAbbuoni (Rebate)
            const detailsDataAbbuoni = this.detailsData.filter((d) => {
              let hasSR = hasSomeTreasury(d.entry_details, "AB");
              // let hasNR = hasNegativeTreasury(d.entry_details, "AB");
              // let totR = totalTreasury(d.entry_details, "AB");
              // return hasSR && hasNR && totR < 0;
              return hasSR;
            });

            // add attivi/passivi column
            this.tableAbbuoni["AB+"] = 0;
            this.tableAbbuoni["AB-"] = 0;

            // set values
            for (const d of detailsDataAbbuoni) {
              let totPos = totalTreasury(d.entry_details, "AB", "+");
              let totNeg = totalTreasury(d.entry_details, "AB", "-");
              // totale dei sospesi
              this.tableAbbuoni["AB+"] = this.tableAbbuoni["AB+"] + totPos;
              this.tableAbbuoni["AB-"] = this.tableAbbuoni["AB-"] + totNeg;
            }
            // delete zero valued keys
            for (const key of Object.keys(this.tableAbbuoni)) {
              if (this.tableAbbuoni[key] === 0) {
                delete this.tableAbbuoni[key];
              }
            }

            // tableSameDate - is_system === 'N' && book_entries.book_date === entry_details.entry_date
            const filterBySameDate = filterBy(null, "book_date");
            const dataSameDate = filterBySameDate(
                this.detailsData,
                this.filter.byCalendar.from
            );
            // console.debug("§§§ dataSameDate §§§");
            // console.debug(dataSameDate);

            const dataSameDateByInsurer = groupByInsurer(dataSameDate);

            const treasuryIndexOnlyNotSystem = this.treasuryIndex.filter(
                (t) =>
                    t.sector.is_system.value === "N" &&
                    t.sector.is_abstract.value === "N"
            );

            this.tableSameDate = sumGross(
                dataSameDateByInsurer,
                treasuryIndexOnlyNotSystem
            );

            this.treasuryGrossSameDateTotals = sumGrossTotals(
                Object.values(this.tableSameDate) || [],
                treasuryIndexOnlyNotSystem
            );
            // console.debug("§§§ this.tableSameDate §§§");
            // console.debug(this.tableSameDate);

            // console.debug("§§§ this.treasuryGrossSameDateTotals §§§");
            // console.debug(this.treasuryGrossSameDateTotals);

            // § tableSameDateBySector (non da regolazione sospesi)
            const sectorIndexOnlyNotSystem = this.sectorIndex.filter(
                (t) => t.is_system.value === "N" && t.is_abstract.value === "N"
            );

            // #551
            // Quindi sono sommati tutti gli importi provenienti dal foglio cassa registrati con forme di pagamento non di sistema e gli importi registrati con movimento vario
            // Non sono considerati i sospesi, i recuperi sospeso, gli acconti e i recuperi acconto.
            // tmp = this.excludeDeferredDetails(this.detailsData, false);
            tmp = this.excludeDeferredDetails(dataSameDate, false);
            tmp = this.excludeAdvancedPaymentsDetails(tmp, false);
            tmp = groupByInsurer(tmp);
            tmp = sumGross(tmp, treasuryIndexOnlyNotSystem);

            // sommo i gross same date delle casse raggruppandoli per comparto, mantenendoli suddivisi per produttore
            // #551
            // for (const [insurer, data] of Object.entries(this.tableSameDate)) {
            for (const [insurer, data] of Object.entries(tmp)) {
              this.tableSameDateBySector[insurer] = {};
              for (const sector of sectorIndexOnlyNotSystem) {
                let gross = 0;
                for (const treasury of sector.treasuries) {
                  gross += data[treasury.code];
                }
                this.tableSameDateBySector[insurer][sector.code] = gross;
              }
            }
            // TODO: define and use less restrictive helper than sumGrossTotalsBySector
            //  in order to avoid grouping and un-grouping by insurer!!!
            // console.debug("§§§ this.tableSameDateBySector §§§");
            // console.debug(this.tableSameDateBySector);

            // treasuryGrossSameDateTotalsBySector
            this.treasuryGrossSameDateTotalsBySector = sumGrossTotalsBySector(
                Object.values(this.tableSameDateBySector),
                sectorIndexOnlyNotSystem
            );

            // card 627 , colonna 'Cassa' (contanti + cassa)
            // le colonne casse e contanti non devono essere mostrate
            this.treasuryGrossSameDateTotalsBySector["CC+CA"] =
                this.treasuryGrossSameDateTotalsBySector["CC"] +
                this.treasuryGrossSameDateTotalsBySector["CA"];
            this.$delete(this.treasuryGrossSameDateTotalsBySector, "CA");
            this.$delete(this.treasuryGrossSameDateTotalsBySector, "CC");

            // delete zero valued keys
            for (const key of Object.keys(
                this.treasuryGrossSameDateTotalsBySector
            )) {
              if (this.treasuryGrossSameDateTotalsBySector[key] === 0) {
                delete this.treasuryGrossSameDateTotalsBySector[key];
              }
            }

            // #1023
            for (const [code, gross] of Object.entries(
                this.treasuryGrossSameDateTotalsBySector
            )) {
              // check if fieldONERIVARI is Y for the given sector's code
              let found = this.sectorIndex.find((e) => e.code === code);
              if (found) {
                if (
                    found[this.fieldONERIVARI] &&
                    found[this.fieldONERIVARI].value === "Y"
                ) {
                  if (!this.treasuryGrossSameDateTotalsBySector["ONERIVARI"]) {
                    this.$set(
                        this.treasuryGrossSameDateTotalsBySector,
                        "ONERIVARI",
                        0
                    );
                  }
                  this.treasuryGrossSameDateTotalsBySector["ONERIVARI"] += gross;
                }
              }
            }

            // tableOverall
            // #552
            // Quindi sono sommati tutti gli importi provenienti dal foglio cassa registrati con forme di pagamento non di sistema e gli importi registrati con movimento vario.
            // Non sono considerati i sospesi, gli acconti ed i recuperi acconto.
            // Come #551 ma con inclusi i recuperi sospesi

            // tmp = this.excludeDeferredDetails(this.detailsData, false);
            tmp = this.excludeAdvancedPaymentsDetails(this.detailsData, false);
            tmp = groupByInsurer(tmp);

            // this.tableOverall = sumGross(
            //   dataByInsurer,
            //   treasuryIndexOnlyNotSystem
            // );
            this.tableOverall = sumGross(tmp, treasuryIndexOnlyNotSystem);
            this.treasuryGrossOverallTotals = sumGrossTotals(
                Object.values(this.tableOverall),
                treasuryIndexOnlyNotSystem
            );

            // tableOverallBySector
            // sommo i gross delle casse raggruppandoli per comparto, mantenendoli suddivisi per produttore
            for (const [insurer, data] of Object.entries(this.tableOverall)) {
              this.tableOverallBySector[insurer] = {};
              for (const sector of sectorIndexOnlyNotSystem) {
                let gross = 0;
                for (const treasury of sector.treasuries) {
                  gross += data[treasury.code];
                }
                this.tableOverallBySector[insurer][sector.code] = gross;
              }
            }
            // console.debug("§§§ this.tableOverallBySector §§§");
            // console.debug(this.tableOverallBySector);

            // § treasuryGrossOverallTotalsBySector
            this.treasuryGrossOverallTotalsBySector = sumGrossTotalsBySector(
                Object.values(this.tableOverallBySector),
                sectorIndexOnlyNotSystem
            );

            // card 628 , colonna 'Cassa' (contanti + cassa)
            // le colonne casse e contanti non devono essere mostrate
            this.treasuryGrossOverallTotalsBySector["CC+CA"] =
                this.treasuryGrossOverallTotalsBySector["CC"] +
                this.treasuryGrossOverallTotalsBySector["CA"];
            this.$delete(this.treasuryGrossOverallTotalsBySector, "CA");
            this.$delete(this.treasuryGrossOverallTotalsBySector, "CC");

            // delete zero valued keys
            for (const key of Object.keys(
                this.treasuryGrossOverallTotalsBySector
            )) {
              if (this.treasuryGrossOverallTotalsBySector[key] === 0) {
                delete this.treasuryGrossOverallTotalsBySector[key];
              }
            }

            // #1023
            for (const [code, gross] of Object.entries(
                this.treasuryGrossOverallTotalsBySector
            )) {
              // check if fieldONERIVARI is Y for the given sector's code
              let found = this.sectorIndex.find((e) => e.code === code);
              if (found) {
                if (
                    found[this.fieldONERIVARI] &&
                    found[this.fieldONERIVARI].value === "Y"
                ) {
                  if (!this.treasuryGrossOverallTotalsBySector["ONERIVARI"]) {
                    this.$set(
                        this.treasuryGrossOverallTotalsBySector,
                        "ONERIVARI",
                        0
                    );
                  }
                  this.treasuryGrossOverallTotalsBySector["ONERIVARI"] += gross;
                }
              }
            }

            // tableConsolidatedTotals
            // NOTA: teoricamente dovrei generare una table come quella della giornata contabile,
            // dove però i valori sono sommati a quelli del consolidamento
            // i dati di consolidamento sono già sommati per cassa
            let allInsurers = [
              ...new Set([
                ...Object.keys(this.tableByInsurer),
                ...Object.keys(this.tableConsolidated),
              ]),
            ];
            for (let insurer of allInsurers) {
              let a = this.tableByInsurer[insurer]
                  ? this.tableByInsurer[insurer]
                  : {};
              let b = this.tableConsolidated[insurer]
                  ? this.tableConsolidated[insurer]
                  : {};
              this.tableConsolidatedTotals[insurer] = this.mergeTotals([a, b]);
            }

            this.treasuryGrossProgressiveTotals = this.mergeTotals([
              this.treasuryGrossTotals,
              this.treasuryGrossConsolidatedTotals,
            ]);

            // § tableConsolidatedTotalsBySector
            let allInsurersBySector = [
              ...new Set([
                ...Object.keys(this.tableByInsurerBySector),
                ...Object.keys(this.tableConsolidatedBySector),
              ]),
            ];
            for (let insurer of allInsurersBySector) {
              let a = this.tableByInsurerBySector[insurer]
                  ? this.tableByInsurerBySector[insurer]
                  : {};
              let b = this.tableConsolidatedBySector[insurer]
                  ? this.tableConsolidatedBySector[insurer]
                  : {};
              this.tableConsolidatedTotalsBySector[insurer] = this.mergeTotals([
                a,
                b,
              ]);
            }

            this.treasuryGrossProgressiveTotalsBySector = this.mergeTotals([
              this.treasuryGrossTotalsBySector,
              this.treasuryGrossConsolidatedTotalsBySector,
            ]);

            /* // #1023: qui non devo calcolare ONERIVARI perché già calcolato nei 2 parametri del precedente mergeTotals
            for (const [code, gross] of Object.entries(
              this.treasuryGrossProgressiveTotalsBySector
            )) {
              // check if fieldONERIVARI is Y for the given sector's code
              let found = this.sectorIndex.find((e) => e.code === code);
              if (found) {
                if (
                  found[this.fieldONERIVARI] &&
                  found[this.fieldONERIVARI].value === "Y"
                ) {
                  if (!this.treasuryGrossProgressiveTotalsBySector["ONERIVARI"]) {
                    this.$set(
                      this.treasuryGrossProgressiveTotalsBySector,
                      "ONERIVARI",
                      0
                    );
                  }
                  this.treasuryGrossProgressiveTotalsBySector["ONERIVARI"] +=
                    gross;
                }
              }
            } */

            // console.debug("================ Consolidated Totals ================")
            // console.debug("treasuryGrossTotals")
            // console.debug(this.treasuryGrossTotals)
            // console.debug("treasuryGrossConsolidatedTotals")
            // console.debug(this.treasuryGrossConsolidatedTotals)
            // console.debug("treasuryGrossProgressiveTotals")
            // console.debug(this.treasuryGrossProgressiveTotals)
            // console.debug("=======================================")

            // tableAllDetails
            // this.tableAllDetails = this.detailsData;
            //this.tableAllDetails = this.handleDeferredDetails(this.detailsData);

            // DEBUG (non filtro il dettagli per data del book entry, ma solo riordino per insurer il response...)
            // this.detailsData = response.data.data.sort(
            //   (a, b) => a.insurer_id - b.insurer_id
            // );
            // console.log("this.detailsData", this.detailsData);

            /* // #1023: e coi dettagli come facciamo???
            for (const d of this.detailsData) {
              for (const ed of d.entry_details) {
                console.debug(ed.treasury?.sector);
                // check if fieldONERIVARI is Y for the given sector's code
                // let found = this.sectorIndex.find((e) => e.code === code);
                // if (found) {
                //   if (
                //     found[this.fieldONERIVARI] &&
                //     found[this.fieldONERIVARI].value === "Y"
                //   ) {
                //     if (!this.treasuryGrossConsolidatedTotals["ONERIVARI"]) {
                //       this.$set(
                //         this.treasuryGrossConsolidatedTotals,
                //         "ONERIVARI",
                //         0
                //       );
                //     }
                //     this.treasuryGrossConsolidatedTotals["ONERIVARI"] += gross;
                //   }
                // }
              }
            } */

            tmp = this.handleDeferredDetails(this.detailsData);
            tmp = this.handleDepositedDetails(tmp);
            this.tableAllDetails = tmp.sort((a, b) =>
                this.getSortString(a).localeCompare(this.getSortString(b))
            );
            // console.log("this.tableAllDetails", this.tableAllDetails);

            // console.debug("§§§ this.tableAllDetails §§§");
            // console.debug(this.tableAllDetails);

            // tableAllDetailsBySector (??? sembrerebbe non vada fatta)
            // for (const sector of this.sectorIndex) {
            //   let gross = 0;
            //   for (const treasury of sector.treasuries) {
            //     gross += data[treasury.code];
            //   }
            //   this.detailsDataBySector[sector.code] = gross;
            // }

            // console.debug("§§§ this.detailsDataBySector §§§");
            // console.debug(this.detailsDataBySector);
            // this.tableAllDetailsBySector = this.detailsDataBySector;
          })
          // .catch((error) => {
          //   console.log("Error", error);
          //   this.$showSnackbar({
          //     preset: "error",
          //     text: `Errore in caricamento movimenti: ${JSON.stringify(error)}`,
          //     actionText: "OK",
          //   });
          // })
          .catch((error) => {
            console.log("Error", error);
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              // text: `Errore in caricamento movimenti: ${JSON.stringify(error)}`,
              text: `${JSON.stringify(errMsg)}`,
              actionText: "OK",
            });
          })
          .finally(() => {
            this.isLoading = false;
            // this.isDrawing = false;
          });
    },
    getNonZeroSector(
        data,
        sectorIndex,
        isSystem,
        isAbstract,
        isAgency = null,
        isHidden = null,
        field = "code"
    ) {
      let nonZero = Object.keys(data);
      let codes = getSectorCodes(
          sectorIndex,
          isSystem,
          isAbstract,
          isAgency,
          isHidden,
          "code"
      ).filter((e) => nonZero.includes(e));
      return sectorIndex
          .filter((e) => codes.includes(e.code))
          .map((e) => e[field]);
    },
    getNonZeroCodes(data) {
      return Object.keys(data);
    },
    // sospesi negativi
    getDeferredIds(item) {
      let ret = item.entry_details
          .filter((e) => e?.treasury?.code === "SS" && parseFloat(e.gross) < 0)
          .map((e) => e.id);
      return ret;
    },
    // acconti negativi
    getDepositedIds(item) {
      let ret = item.entry_details
          .filter((e) => e?.treasury?.code === "AC" && parseFloat(e.gross) < 0)
          .map((e) => e.id);
      return ret;
    },
    // acconti negativi
    getAdvancedPaymentsIds(item) {
      let ret = item.entry_details
          .filter((e) => e?.treasury?.code === "AC" && parseFloat(e.gross) < 0)
          .map((e) => e.id);
      return ret;
    },
    /**
     * Restituisce un rimaneggiamento di "data", secondo questo algoritmo:
     * per ogni item in data:
     *
     *   a) se in entry_details esistono degli item con treasury_id 3 && gross < 0:
     *    a.1) cerca nei loro entry_details gli id collegati
     *    a.2) inserisce il dettaglio dell'id principale e degli id collegati in un oggetto di appoggio,
     *       svuotando i loro entry_details = [] (poiché non servono ai fini del rendering):
     *
     *      key = id del tresury_id 3 con gross negativo
     *      value = [
     *        entry detail principale (svuotato),
     *        tutti gli entry detail del entry detail principale (svuotati)
     *     ]
     *     es:
     *     oggetto di appoggio = {
     *       45: [
     *         {id: 45, title: "", book_entry_id: 11, treasury_id: 3, gross: -25, detail_date: "2022-05-04",…},
     *         {id: 46, title: "", book_entry_id: 11, treasury_id: 7, gross: 25, detail_date: "2022-05-04",…}
     *       ],
     *       X: []
     *      }
     *    a.3) rimuove dagli entry_details di item tutti quelli con id nel "value" (nell'array dell'oggetto di appoggio) (es: 45 e 46)
     *   b) prima di passare al prossimo item, se l'oggetto di appoggio non è vuoto:
     *    b.1.1) se non ci sono entry details, lo scarto
     *    b.1.2) duplica l'item attuale impostando i suoi entry_details al "value" dell'oggetto di appoggio
     *    b.2) aggiunge al duplicato il flag "virtual: true", che servirà in fase di rendering per evidenziare queste righe vituali
     *    b.3) nella colonna "data" compare il giorno dell'operazione di recupero sospeso (ovvero il momento in cui l'utente chiude definitivamente o parzialmente il sospeso)
     */

    handleDeferredDetails(data) {
      let ret = []; // conterrà una versione manipolata di data
      let opDate; // conterrà la data b.3)
      let bookDate;
      let clone = JSON.parse(JSON.stringify(data));
      for (const item of clone) {
        opDate = ""; // #501
        bookDate = item.book_date;
        // console.log("book date:", bookDate);
        // a)
        let principal_ids = this.getDeferredIds(item);
        let obj = {};
        for (const pid of principal_ids) {
          // trovo il dettaglio del principale (quello che ha cassa 3 e gross < 0)
          // a.1)
          // let pdetail = JSON.parse(
          //   JSON.stringify(item.entry_details.find((e) => e.id === pid))
          // );
          let pdetail = Object.assign(
              {},
              item.entry_details.find((e) => e.id === pid)
          );
          // TODO quale data va usata #501 punto 1?
          opDate = pdetail.detail_date;
          // console.log("rec date:", opDate);

          // prendo tutti i suoi dettagli collegati
          let ddetails = pdetail.entry_details;
          // a.2)
          pdetail.entry_details = []; // svuota i dettagli del principale

          obj[pid] = [pdetail, ...ddetails];
          let ids = obj[pid].map((e) => e.id);

          // a.3)
          if (obj[pid].length) {
            item.entry_details = item.entry_details.filter(
                (e) => !ids.includes(e.id)
            );
          }
        }
        // b)
        // b.1.1)
        // story-409: non si visualizza una riga con importo 0 dello stesso movimento
        if (item.entry_details.length) {
          ret.push(item); // aggiunge l'item originale, privato del dettaglio "principale" e dei suoi dettagli collegati
        }
        for (const value of Object.values(obj)) {
          // b.1.2)
          let dup = Object.assign({}, item);
          // b.2)
          dup.virtual = true;
          // b.3)
          dup.book_date = opDate; // che equivale banalmente alla data del filtro iniziale

          dup.op_date = bookDate; // riporta la data del book entry
          dup.entry_details = value;
          ret.push(dup); // aggiunge il duplicato, che contiene il dettaglio "pricipale" e i suoi collegati
        }
      }
      return ret;
    },
    // clone di handleDeferredDetails
    // TODO: unificarle aggiungendo parametro "code"
    handleDepositedDetails(data) {
      let ret = []; // conterrà una versione manipolata di data
      let opDate; // conterrà la data b.3)
      let bookDate;
      let clone = JSON.parse(JSON.stringify(data));
      for (const item of clone) {
        opDate = ""; // #501
        bookDate = item.book_date;
        // console.log("book date:", bookDate);
        // a)
        let principal_ids = this.getDepositedIds(item);
        let obj = {};
        for (const pid of principal_ids) {
          // trovo il dettaglio del principale (quello che ha cassa 3 e gross < 0)
          // a.1)
          // let pdetail = JSON.parse(
          //   JSON.stringify(item.entry_details.find((e) => e.id === pid))
          // );
          let pdetail = Object.assign(
              {},
              item.entry_details.find((e) => e.id === pid)
          );
          // TODO quale data va usata #501 punto 1?
          opDate = pdetail.detail_date;
          // console.log("rec date:", opDate);

          // prendo tutti i suoi dettagli collegati
          let ddetails = pdetail.entry_details;
          // a.2)
          pdetail.entry_details = []; // svuota i dettagli del principale

          obj[pid] = [pdetail, ...ddetails];
          let ids = obj[pid].map((e) => e.id);

          // a.3)
          if (obj[pid].length) {
            item.entry_details = item.entry_details.filter(
                (e) => !ids.includes(e.id)
            );
          }
        }
        // b)
        // b.1.1)
        // story-409: non si visualizza una riga con importo 0 dello stesso movimento
        if (item.entry_details.length) {
          ret.push(item); // aggiunge l'item originale, privato del dettaglio "principale" e dei suoi dettagli collegati
        }
        for (const value of Object.values(obj)) {
          // b.1.2)
          let dup = Object.assign({}, item);
          // b.2)
          dup.virtual = true;
          // b.3)
          dup.book_date = opDate; // che equivale banalmente alla data del filtro iniziale

          dup.op_date = bookDate; // riporta la data del book entry
          dup.entry_details = value;
          ret.push(dup); // aggiunge il duplicato, che contiene il dettaglio "pricipale" e i suoi collegati
        }
      }
      return ret;
    },
    /**
     * esclude da "data" i details relativi a:
     * a) movimenti vari (in base al parametro skip_various_accountings)
     * b) recuperi dei sospesi
     */
    excludeAdvancedPaymentsDetails(data, skip_various_accountings = true) {
      let ret = []; // conterrà una versione manipolata di data
      let clone = JSON.parse(JSON.stringify(data));
      for (const item of clone) {
        // a)
        // se various_accountings non vuoto (o specularmente insurance_ancillaries vuoto), salto il bookentry
        if (
            skip_various_accountings &&
            item.various_accountings &&
            item.various_accountings.length
        )
          continue;
        let principal_ids = this.getAdvancedPaymentsIds(item);
        for (const pid of principal_ids) {
          let pivotIds = [];
          // trovo il dettaglio del principale (quello che ha cassa 3 e gross < 0)
          let pdetail = Object.assign(
              {},
              item.entry_details.find((e) => e.id === pid)
          );
          // prendo tutti gli id dei dettagli collegati
          let ddetails = pdetail.entry_details;
          pivotIds = ddetails.map((e) => e.id);
          // b)
          if (pivotIds.length) {
            item.entry_details = item.entry_details.filter(
                (e) => !pivotIds.includes(e.id)
            );
          }
        }
        ret.push(item);
      }
      return ret;
    },
    /**
     * esclude da "data" i details relativi a:
     * a) movimenti vari (in base al parametro skip_various_accountings)
     * b) recuperi dei sospesi
     */
    excludeDeferredDetails(data, skip_various_accountings = true) {
      let ret = []; // conterrà una versione manipolata di data
      let clone = JSON.parse(JSON.stringify(data));
      for (const item of clone) {
        // a)
        // se various_accountings non vuoto (o specularmente insurance_ancillaries vuoto), salto il bookentry
        if (
            skip_various_accountings &&
            item.various_accountings &&
            item.various_accountings.length
        )
          continue;
        let principal_ids = this.getDeferredIds(item);
        for (const pid of principal_ids) {
          let pivotIds = [];
          // trovo il dettaglio del principale (quello che ha cassa 3 e gross < 0)
          let pdetail = Object.assign(
              {},
              item.entry_details.find((e) => e.id === pid)
          );
          // prendo tutti gli id dei dettagli collegati
          let ddetails = pdetail.entry_details;
          pivotIds = ddetails.map((e) => e.id);
          // b)
          if (pivotIds.length) {
            item.entry_details = item.entry_details.filter(
                (e) => !pivotIds.includes(e.id)
            );
          }
        }
        ret.push(item);
      }
      return ret;
    },
    /**
     * https://dev.to/ramonak/javascript-how-to-merge-multiple-objects-with-sum-of-values-43fd
     */
    mergeTotals(data) {
      const ret = {};
      data.forEach((item) => {
        for (let [key, value] of Object.entries(item)) {
          if (ret[key]) {
            ret[key] += value;
          } else {
            ret[key] = value;
          }
        }
      });
      return ret;
    },
    resetData() {
      this.detailsData = [];
      this.detailsDataBySector = [];
      // this.consolidatedData = [];
      // this.treasuryIndex = [];
      // this.sectorIndex = [];
      this.treasuryGrossTotals = 0;
      this.treasuryGrossTotalsBySector = 0;
      this.treasuryGrossConsolidatedTotals = 0;
      this.treasuryGrossConsolidatedTotalsBySector = 0;
      this.treasuryGrossSameDateTotals = 0;
      this.treasuryGrossSameDateTotalsBySector = 0; // CHECK lo uso?
      this.treasuryGrossOverallTotals = 0;
      this.treasuryGrossOverallTotalsBySector = 0;
      this.treasuryGrossProgressiveTotals = 0;
      this.treasuryGrossProgressiveTotalsBySector = 0;
      this.tableConsolidated = {};
      this.tableConsolidatedBySector = {};
      this.tableByInsurer = {};
      this.tableByInsurerBySector = {};
      this.tableByMandateCode = {};
      this.tableByMandateCodeBySector = {};
      this.tableSospesi = {};
      this.tableAbbuoni = {};
      this.tableSameDate = {};
      this.tableSameDateBySector = {};
      this.tableOverall = {};
      this.tableOverallBySector = {};
      this.tableConsolidatedTotals = {};
      this.tableConsolidatedTotalsBySector = {};
      this.tableAllDetails = {};
      this.tableAllDetailsBySector = {};
      this.changedIds = [];
    },
    onDrawFoglioQuadrature(date) {
      this.$bvModal.hide("viewConsolidationCalendar");
      this.filter.byCalendar.from = date;
      // #686
      this.onProgressiveChange(
          this.getSetting()("attribute_ACCT_value"),
          date
      ).then(() => {
        // if (this.onDetailDateSelect(date)) {
        this.drawFoglioQuadrature();
        // }
      });
    },
    onDetailDateSelect(date) {
      this.resetData();
      // typed date validity
      // FIXME: moment(date, "DD/MM/YYYY").isSameOrBefore(this.minDate) non working
      if (
          this.isProgressive &&
          moment(date, "DD/MM/YYYY").isSameOrBefore(
              moment(this.minDate, "DD/MM/YYYY")
          )
      ) {
        let errMsg = `La data non può essere uguale o precedente a ${this.toLocaleDate(
            this.minDate
        )}`;
        this.$showSnackbar({
          preset: "info",
          text: `${errMsg}`,
        });
        this.filter.byCalendar.from = null;
        return false;
      }
      return true;
    },
    onLoadLastConsolidated(viewDate = null) {
      this.isLoading = true;
      let queryString = null;
      const Repo = RepositoryFactory.get("consolidated_treasury");
      // #686 se c'è date, devo recuperare la data di consolidamento precedente, e la salvo in this.previously_consolidated_at
      if (viewDate) {
        // chiamo backend passando date...
        queryString = `byLastConsolidated=${viewDate}`; // dipende da #685
      } else {
        queryString = "byLastConsolidated";
      }
      this.isConsolidateLoading = true;
      return Repo.index(queryString)
          .then((response) => {
            let lastDate =
                response.data && response.data.data.length
                    ? response.data.data[0].last_created
                    : null;
            // #686
            if (!viewDate) {
              // aggiorno la data di ultimo consolidato
              this.form.consolidated_treasury.consolidated_at = lastDate;
            } else {
              // ho premuto "Visualizza" sul viewCalendar, devo solo impostare previously_consolidated_at
              // la data di ultimo consolidato rimane invariata
              this.previously_consolidated_at = lastDate;
              this.$showSnackbar({
                preset: "info",
                text: `Data Consolidamento Precedente impostata a ${this.customFormatDate(
                    this.previouslyConsolidatedAt
                )}`,
              });
            }
            // get the consolidated_treasury data
            if (lastDate) {
              queryString = `perPage=0&byCalendar=${lastDate},${lastDate}`;
              return Repo.index(queryString)
                  .then((response) => {
                    this.consolidatedData = response.data.data;
                  })
                  .catch((error) => {
                    let errMsg = this.$getErrorMessage(error);
                    this.$showSnackbar({
                      preset: "error",
                      text: `${errMsg}`,
                    });
                  })
                  .finally(() => {
                    this.isConsolidateLoading = false;
                  });
            } else {
              this.isConsolidateLoading = false;
            }
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            // this.isConsolidateLoading = false;
          })
          .finally(() => {
            // solo se non sto "visualizzando" date precedenti...
            // if (!this.previouslyConsolidatedAt) {
            //   this.$showSnackbar({
            //     preset: "info",
            //     text: `${
            //       this.form.consolidated_treasury.consolidated_at
            //         ? "Data Consolidamento impostata a " +
            //           this.customFormatDate(
            //             this.form.consolidated_treasury.consolidated_at
            //           )
            //         : "Nessuna data di Consolidamento presente"
            //     }`,
            //   });
            // }
            console.log("onLandData", this.onLandData);
            console.log("consumedLandData", this.consumedLandData);
            if (
                !this.consumedLandData &&
                this.onLandData &&
                this.onLandData?.date_from
            ) {
              this.filter.byCalendar.from = this.onLandData.date_from;
              this.consumedLandData = true;
              this.previously_consolidated_at = null;
              this.onLandData = {};
              this.drawFoglioQuadrature();
            } else {
              this.isLoading = false;
            }
          });
    },
    onProgressiveChange(checkedOption, viewDate = null) {
      this.consolidatedData = [];
      // this.isConsolidateLoading = true;
      if (checkedOption === "Y") {
        return this.onLoadLastConsolidated(viewDate);
      } /* else {
        this.isConsolidateLoading = false;
      } */
    },
    calcPartialGrosses(amount, parts) {
      let ret = [];
      const part_amount = parseFloat(parseInt(amount / parts));
      const last_part_amount = parseFloat(
          (amount - part_amount * (parts - 1)).toFixed(2)
      );
      ret.push(last_part_amount); // l'eccedente sulla prima cassa
      let i = 1;
      while (i < parts) {
        ret.push(part_amount);
        i++;
      }
      return ret;
    },
    saveConsolitated() {
      let data;
      let Repo;
      // let keysMap;
      let keysSectorMap;
      let payload = {};
      // let promises = [];
      if (this.isProgressive) {
        console.debug(
            "saving consolidation data for day: ",
            this.filter.byCalendar.from
        );
        // salvare una giornata di consolidatura se:
        // - quella giornata NON è già consolidata
        // - non esistono movimenti di cassa non astratte (entry_detail) in giornate precedenti non consolidate successive alla consolidatura precedente
        // data = this.tableConsolidated;
        data = this.tableConsolidatedTotalsBySector;
      } else {
        console.debug(
            "saving today data as consolidation for day: ",
            this.filter.byCalendar.from
        );
        // salvare una giornata di consolidatura se quella giornata non è già consolidata.
        data = this.tableByInsurer;
      }
      Repo = RepositoryFactory.get("consolidated_treasury");
      // TODO call BE and save the consolidated_treasury data
      // keysMap = Object.assign(
      //   {},
      //   ...this.treasuryIndex.map((item) => ({ [item.code]: item.id }))
      // );

      keysSectorMap = Object.assign(
          {},
          ...this.sectorIndex.map((item) => ({
            [item.code]: item.id,
          }))
      );
      // // devo salvare il form, riga per riga (1 insurer), colonna per colonna (1 treasury)
      // for (const [key, obj] of Object.entries(data)) {
      //   for (const code of Object.keys(obj)) {
      //     payload = {
      //       insurer_id: key && key != "null" ? parseInt(key) : null,
      //       treasury_id: parseInt(keysMap[code]),
      //       gross: obj[code],
      //       // consolidated_at: this.filter.byAttribute.detail_date,
      //       consolidated_at: this.filter.byCalendar.from,
      //     };
      //     promises.push(Repo.store(payload));
      //   }
      // }
      this.isLoading = true;
      let p_key = 0; // key per il payload "massivo"
      for (const [key, obj] of Object.entries(data)) {
        for (const sector_code in obj) {
          // get the treasury codes associated with this sector
          const sector_id = parseInt(keysSectorMap[sector_code]);
          const treasury_ids = this.treasuryIndex
              .filter((e) => e.sector_id === sector_id)
              .map((item) => item.id);
          /* .map((item) => ({
              [item.code]: item.id,
            })) */
          const partial_grosses = this.calcPartialGrosses(
              obj[sector_code],
              treasury_ids.length
          );

          // BULK
          let index = 0;
          for (const treasury_id of treasury_ids) {
            payload[p_key] = {
              insurer_id:
                  key && key != "null" && parseInt(key) ? parseInt(key) : null,
              treasury_id,
              gross: partial_grosses[index],
              consolidated_at: this.filter.byCalendar.from,
            };
            index++;
            p_key++;
          }
        }
      }
      // Promise.all(promises)
      Repo.bulk_store(payload)
          .then(() => {
            // this.commitProgressive();
            this.$showSnackbar({
              preset: "success",
              // text: `Azione Completata: Consolidamento Creato per il giorno ${this.filter.byAttribute.detail_date}`,
              text: `Azione Completata: Consolidamento creato per il giorno ${this.customFormatDate(
                  this.filter.byCalendar.from
              )}`,
            });
            // reset byCalendar
            this.filter.byCalendar.from = null;
            // recupero la nuova data
            this.onProgressiveChange(
                this.getSetting()("attribute_ACCT_value") //this.filter.byAttribute.progressive ? "Y" : "N"
            );
            // #686 CHECK devo resettare
            this.resetData();
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });

      // REVIEW si spera che il BE riesca almeno a salvare tutti i treasury insieme
      // CHECK mass store ???
      // payload = {};
      // for(const key in Object.keys(data)) {
      //   payload[key] = []
      //   let obj = this.form[name][key];
      //   for(const code in Object.keys(obj)) {
      //     payload[key].push({
      //       insurer_id: key ? key : null,
      //       treasury_id: parseInt(keysMap[code]),
      //       gross: obj[code],
      //       consolidated_at: this.filter.byAttribute.detail_date
      //     })
      //   }
      // }

      // Repo.store(payload)
      //   .then(() => {
      //     // this.commitProgressive();
      //     showSnackbar({
      //       preset: "success",
      //       text: `Azione Completata: Consolidamento Creato per il giorno ${this.filter.byAttribute.detail_date}`,
      //     });
      //   })
      // .catch((error) => {
      //     let errMsg = this.$getErrorMessage(error);
      //     showSnackbar({
      //       preset: "error",
      //       text: `${errMsg}`,
      //     });
      //   })
      //   .finally(() => {
      //     this.isLoading = false;
      //   })
    },
    onViewConsCal() {
      this.$bvModal.show("viewConsolidationCalendar");
    },
    hasDeferred(data) {
      let found;
      found = data.find(
          (entry_detail) =>
              entry_detail.gross < 0 && entry_detail.treasury?.code === "SS"
      );
      if (found) {
        return true;
      }

      return false;
    },
    hasDeposited(data) {
      let found;
      found = data.find(
          (entry_detail) =>
              entry_detail.gross < 0 && entry_detail.treasury?.code === "AC"
      );
      if (found) {
        return true;
      }

      return false;
    },
    hasVariousAccounting(data) {
      return data.various_accountings && data.various_accountings.length
          ? true
          : false;
    },
    hasInsuranceAncillary(data) {
      return data.insurance_ancillaries && data.insurance_ancillaries.length
          ? true
          : false;
    },
    getSortString(item) {
      let ret = "D"; // per ultimi quelli che non hanno match nei case
      switch (true) {
        case this.hasDeferred(item.entry_details):
          ret = "C";
          break;
        case this.hasVariousAccounting(item):
          ret = "A";
          break;
        case this.hasInsuranceAncillary(item):
          ret = "B";
          break;
      }
      return ret;
    },
    renderOperation(data) {
      let ret;
      let date;
      switch (true) {
        case this.hasDeferred(data.entry_details):
          // TODO quale data va usata #501 punto 2?
          date = data.op_date;
          ret = `Op. Sospeso del ${this.toLocaleDate(date)}`;
          break;
        case this.hasDeposited(data.entry_details):
          // TODO quale data va usata #501 punto 2?
          date = data.op_date;
          ret = `Op. Acconto del ${this.toLocaleDate(date)}`;
          break;
        case this.hasVariousAccounting(data):
          ret = data.title;
          break;
        case this.hasInsuranceAncillary(data):
          ret = data.book_tags.length ? data.book_tags[0].title : "";
          break;
      }
      return ret;
    },
    // card 648
    getGrossByCode(entry_details, code) {
      /* // DEBUG: AB is Y
      entry_details.forEach((entry_detail) => {
        entry_detail.treasury.sector[this.fieldONERIVARI] = {
          text: entry_detail.treasury?.sector?.code == "AB" ? "Sì" : "No",
          value: entry_detail.treasury?.sector?.code == "AB" ? "Y" : "N",
        };
      }); */
      const entryDetailsByCode = entry_details
          .filter((entry_detail) => entry_detail.treasury)
          // .filter((entry_detail) => entry_detail.treasury?.sector?.code === code);
          // #1023
          .filter(
              (entry_detail) =>
                  entry_detail.treasury?.sector[this.fieldONERIVARI]?.value === "N" &&
                  entry_detail.treasury?.sector?.code === code
          );
      return entryDetailsByCode.reduce(
          (previousValue, currentValue) => previousValue + currentValue.gross,
          0
      );
    },
    getOneriVariGross(entry_details) {
      /* // DEBUG: AB is Y
      entry_details.forEach((entry_detail) => {
        entry_detail.treasury.sector[this.fieldONERIVARI] = {
          text: entry_detail.treasury?.sector?.code == "AB" ? "Sì" : "No",
          value: entry_detail.treasury?.sector?.code == "AB" ? "Y" : "N",
        };
      }); */
      const entryDetailsOneriVari = entry_details
          .filter((entry_detail) => entry_detail.treasury)
          // #1023
          .filter(
              (entry_detail) =>
                  entry_detail.treasury?.sector[this.fieldONERIVARI]?.value === "Y"
          );
      return entryDetailsOneriVari.reduce(
          (previousValue, currentValue) => previousValue + currentValue.gross,
          0
      );
    },
    getRegistry(data) {
      return data.registries.length
          ? data.registries[0].status_registry.value === 1
              ? data.registries[0].attributables.CNAM
              : data.registries[0].attributables.SURN +
              " " +
              data.registries[0].attributables.NAME
          : "";
    },
    sumPivot(array, field) {
      let initialValue = 0;
      let sum = array.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.pivot[field];
      }, initialValue);
      return sum;
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
    }),
    ...mapGetters("auth", {
      getSetting: "setting",
    }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
  },
  components: {
    BaseIcon,
    ConsolidationCalendarModal,
    BaseDatepicker,
    ExportOptions,
    // BaseRadio,
  },
  computed: {
    canGenerate() {
      return this.filter.byCalendar.from && !this.isConsolidateLoading;
    },
    canConsolidate() {
      if (!this.isProgressive) {
        // return this.previouslyConsolidatedAt >= this.consolidatedAt;
        return this.previouslyConsolidatedAt
            ? moment(this.previouslyConsolidatedAt, "DD/MM/YYYY").isSameOrAfter(
                moment(this.consolidatedAt, "DD/MM/YYYY")
            )
            : true;
      } else {
        // return (
        //   this.canGenerate &&
        //   this.previouslyConsolidatedAt >= this.consolidatedAt
        // );
        return this.canGenerate && this.previouslyConsolidatedAt
            ? moment(this.previouslyConsolidatedAt, "DD/MM/YYYY").isSameOrAfter(
                moment(this.consolidatedAt, "DD/MM/YYYY")
            )
            : true;
      }
    },
    isProgressive() {
      return this.getSetting()("attribute_ACCT_value") === "Y" ? true : false;
    },
    progressiveLabel() {
      return this.isProgressive ? "Progressivo" : "Non progressivo";
    },
    consolidatedAt() {
      return this.form.consolidated_treasury.consolidated_at;
    },
    previouslyConsolidatedAt() {
      return this.previously_consolidated_at;
    },
    minDate() {
      // console.log(
      //   "minDate: ",
      //   moment(this.consolidatedAt).add(1, "d").format("YYYY-MM-DD")
      // );
      // imposto minDate solo se in modalità progressiva
      return this.isProgressive
          ? moment(this.consolidatedAt).add(1, "d").format("YYYY-MM-DD")
          : null;
      // // #686
      // return this.isProgressive
      //   ? !this.previouslyConsolidatedAt
      //     ? moment(this.consolidatedAt).add(1, "d").format("YYYY-MM-DD")
      //     : moment(this.previouslyConsolidatedAt)
      //         .add(1, "d")
      //         .format("YYYY-MM-DD")
      //   : null;
    },
  },
  beforeMount() {
    this.isLoading = true;
    //load sectors along with and related treauries
    /* let SectorRepository = RepositoryFactory.get("sector");
    let queryString = "byTreasury&perPage=0";
    SectorRepository.index(queryString)
      .then((response) => {
        this.sectorIndex = response.data.data;
        // DANIELE: andrei comunque a salvare essendo un'opzione che va a nascondere o abilitare la visualizzazione.
        // Significa che se tengo nascosto il campo sempre, poi un giorno lo riabilito, non dovrei star lì a deconsolidare tutto
        // .filter(
        //   (e) => e.is_hidden.value === "N"
        // );
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `Errore in caricamento comparti: ${errMsg}`,
          actionText: "OK",
        });
      })
      .finally(() => {
        // load treasuries
        this.treasuryIndex = this.$store.state.auth.treasuries;
        this.onProgressiveChange(
          this.getSetting()("attribute_ACCT_value") //this.filter.byAttribute.progressive ? "Y" : "N"
        );
      }); */

    // this.sectorIndex = this.$store.state.auth.sectors;
    // #961 posizionare "Entrate - Provvigioni" dopo "Provvigioni"
    // NOTE devo clonare perché la modifica deve essere locale, cioè non su vuex!
    // virtual position for Entrate - Provvigioni is after sector with code=PR
    this.sectorIndex = JSON.parse(
        JSON.stringify(this.$store.state.auth.sectors)
    );
    let index = this.sectorIndex.findIndex((e) => e.code === "PR");
    this.sectorIndex.splice(index + 1, 0, {
      code: "EN-PR",
      title: "Entrate - Provvigioni",
      treasuries: [],
      is_abstract: {
        value: "Y",
      },
      is_system: {
        value: "Y",
      },
      is_agency: {
        value: "N",
      },
      is_hidden: {
        value: "N",
      },
      [this.fieldONERIVARI]: {
        value: "N", // #1023 prevent this field to be collected in ONERIVARI
      },
    });
    /* // DEBUG: impostare un sector (es: AB) con il flag per gli oneri vari...
    this.sectorIndex.find((e) => e.code === "AB")[this.fieldONERIVARI] = {
      text: "Sì",
      value: "Y",
    };
    // DEBUG rimuovere qui sopra !!! */

    // DANIELE: andrei comunque a salvare essendo un'opzione che va a nascondere o abilitare la visualizzazione.
    // Significa che se tengo nascosto il campo sempre, poi un giorno lo riabilito, non dovrei star lì a deconsolidare tutto
    // .filter(
    //   (e) => e.is_hidden.value === "N"
    // );
    this.treasuryIndex = this.$store.state.auth.treasuries;
    this.onProgressiveChange(
        this.getSetting()("attribute_ACCT_value") //this.filter.byAttribute.progressive ? "Y" : "N"
    );
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("councils"))
      resources.push(
          this.$store
              .dispatch(`${path}councils`)
              .then(() => {
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
      );
    if (!this.hasLoadedResource("provinces"))
      resources.push(
          this.$store
              .dispatch(`${path}provinces`)
              .then(() => {
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
      );
    if (!this.hasLoadedResource("countries")) {
      resources.push(
          this.$store
              .dispatch(`${path}countries`)
              .then(() => {
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
      );
    }
    if (resources.length) {
      this.getResources(resources)
          .then(() => {
          })
          .catch((error) => {
            console.log("One or more promises crashed... :|");
            this.onFailure(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    } else {
      this.isLoading = false;
    }
    /* */
  },
};
</script>

<style scoped>
:deep(body:has(.v-sidebar-menu.vsm_collapsed) [data-type="overflow-x"]) {
  width: calc(100vw - (0.75rem * 2) - 2px - (0.25rem * 2) - (1rem * 2) - 50px);
  overflow-x: scroll;
}

:deep(body:has(.v-sidebar-menu.vsm_expanded) [data-type="overflow-x"]) {
  width: calc(100vw - (0.75rem * 2) - 2px - (0.25rem * 2) - (1rem * 2) - 240px);
  overflow-x: scroll;
}

:deep(.consolida-btn-wrapper) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: #f3f0ea;
  padding: 10px;
  border-top: 1px solid #ccc;
}

table {
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  border: 1px solid lightgrey;
  border-collapse: collapse;
}

table tr th,
td {
  padding: 2px 4px;
  text-align: right;
  border: 1px solid lightgrey;
  border-collapse: collapse;
}

.xsw {
  width: 6%;
  min-width: 80px;
  max-width: 160px;
}

.sw {
  width: 8%;
  min-width: 100px;
  max-width: 200px;
}

.mw {
  width: 10%;
  min-width: 120px;
  max-width: 240px;
}

.verticalLine {
  border-right: 2px solid #f9991e;
}

.totals {
  background-color: #e1ddda;
  font-weight: bold;
}

.virtual {
  background-color: #facf96;
}
</style>
